import React from 'react';
import propTypes from 'prop-types';
import {
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  MODAL_SIZES,
} from '@user-interviews/ui-design-system';

import { surveyPropType } from '../prop_types';

import { SectionSkipLogic } from './section_skip_logic';

import './skip_logic_modal.scss';

export function SkipLogicModal({
  isReadonly,
  sectionId,
  survey,
  onRequestCancel,
  onRequestClose,
}) {
  const section = sectionId ? survey.sections[sectionId] : {};

  const { skipLogicIds } = section;
  const skipLogicsIncomplete = skipLogicIds !== undefined && !skipLogicIds.every((skipLogicId) => {
    const skipLogic = survey.skipLogics[skipLogicId];
    const conditionsCompleted = skipLogic.conditionIds.every((conditionId) => {
      const condition = survey.skipLogicConditions[conditionId];
      const conditionCompleted = condition.questionId && condition.answerId;
      return conditionCompleted;
    });
    return conditionsCompleted;
  });

  return (
    <Modal
      className="SkipLogicModal"
      isOpen={!!sectionId}
      size={MODAL_SIZES.LARGE}
    >
      <ModalHeader
        title={`${isReadonly ? 'Skip' : 'Edit skip'} logic for ${section.title}`}
        titleId="skip-logic-modal-title"
      />
      <ModalBody>
        <p
          className="SkipLogicModal__clarifier"
        >
          Skip logic can only be applied to single or multi-choice questions.
        </p>
        <SectionSkipLogic
          isReadonly={isReadonly}
          section={section}
          survey={survey}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          variant="transparent"
          onClick={onRequestCancel}
        >
          Cancel
        </Button>
        <Button
          disabled={skipLogicsIncomplete}
          variant="primary"
          onClick={onRequestClose}
        >
          Done
        </Button>
      </ModalFooter>
    </Modal>
  );
}

SkipLogicModal.propTypes = {
  isReadonly: propTypes.bool.isRequired,
  sectionId: propTypes.string,
  survey: surveyPropType.isRequired,
  onRequestCancel: propTypes.func.isRequired,
  onRequestClose: propTypes.func.isRequired,
};

SkipLogicModal.defaultProps = {
  sectionId: undefined,
};
