import React from 'react';
import * as propTypes from 'lib/prop_types';

import { COLORS } from '@user-interviews/ui-design-system';

/* eslint-disable max-len */
function BrowserSearch({ className }) {
  return (
    <svg
      className={`BrowserSearch ${className}`}
      fill="none"
      height="48"
      viewBox="0 0 48 48"
      width="48"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M34.083 42H1V4H47V42H44.333"
          stroke={COLORS.UX_BLUE_400}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M5 11C5.55228 11 6 10.5523 6 10C6 9.44772 5.55228 9 5 9C4.44772 9 4 9.44772 4 10C4 10.5523 4.44772 11 5 11Z"
          fill={COLORS.UX_BLUE_400}
        />
        <path
          d="M8 11C8.55228 11 9 10.5523 9 10C9 9.44772 8.55228 9 8 9C7.44772 9 7 9.44772 7 10C7 10.5523 7.44772 11 8 11Z"
          fill={COLORS.UX_BLUE_400}
        />
        <path
          d="M11 11C11.5523 11 12 10.5523 12 10C12 9.44772 11.5523 9 11 9C10.4477 9 10 9.44772 10 10C10 10.5523 10.4477 11 11 11Z"
          fill={COLORS.UX_BLUE_400}
        />
        <path
          d="M15 10H47"
          stroke={COLORS.UX_BLUE_400}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M24 37C30.0751 37 35 32.0751 35 26C35 19.9249 30.0751 15 24 15C17.9249 15 13 19.9249 13 26C13 32.0751 17.9249 37 24 37Z"
          stroke={COLORS.UX_BLUE_400}
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          d="M32 34L45 47.0068"
          stroke={COLORS.UX_BLUE_400}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            fill="white"
            height="48"
            width="48"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

BrowserSearch.propTypes = {
  className: propTypes.string,
};

BrowserSearch.defaultProps = {
  className: '',
};

export default BrowserSearch;
