import React, { useRef, useState } from 'react';
import propTypes from 'prop-types';

import { faCaretDown } from 'lib/font_awesome/solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Manager, Reference, Popper } from 'react-popper';

import { TrackedButton } from 'common/analytics';
import { useOnClickOutside } from 'hooks/use_on_click_outside';

import {
  SHOW_DATA_ACTIONS_TEXT,
  SORT_TYPE_DATE,
  SORT_TYPE_NUMERIC,
  SORT_TYPE_STRING,
} from '../../../lib/constants/sort_data_actions_constants';

import { SortActions } from './sort_actions';
import { SortIndicator } from './sort_indicator';

import './sort_data_actions.scss';

export function SortDataActions({
  sortableType,
  sortParamKey,
  sortParamString,
  trackingEventName,
  onSortChanged,
}) {
  const sortPopperRef = useRef();

  const [showDataSortingActions, setShowDataSortingActions] = useState(false);

  useOnClickOutside(sortPopperRef, () => setShowDataSortingActions(false));
  const handleShowDataSortingActions = () => {
    setShowDataSortingActions(!showDataSortingActions);
  };

  return (
    <span className="SortDataActions">
      <Manager>
        <Reference>
          {
            ({ ref }) => (
              <span ref={ref}>
                <SortIndicator
                  sortableType={sortableType}
                  sortParamKey={sortParamKey}
                  sortParamString={sortParamString}
                />
                <TrackedButton
                  aria-label={`${SHOW_DATA_ACTIONS_TEXT} for sorting by ${sortParamKey}`}
                  event={
                    `${trackingEventName} - Show Sorting Actions for ${sortParamKey} clicked`
                  }
                  variant="transparent"
                  onClick={handleShowDataSortingActions}
                >
                  <FontAwesomeIcon icon={faCaretDown} />
                </TrackedButton>
              </span>
            )
          }
        </Reference>
        {
          showDataSortingActions && (
            <Popper
              modifiers={[
                { name: 'offset', options: { offset: [10, 0] } },
                { name: 'flip', enabled: false },
                { name: 'preventOverflow', options: { altAxis: true } },
              ]}
              placement="right"
            >
              {
                ({ ref, placement, style }) => (
                  <div
                    className="SortDataActions__controls"
                    data-placement={placement}
                    ref={ref}
                    style={style}
                  >
                    <span
                      ref={sortPopperRef}
                    >
                      <SortActions
                        hideActionsAfterSortChanged={handleShowDataSortingActions}
                        sortableType={sortableType}
                        sortParamKey={sortParamKey}
                        sortParamString={sortParamString}
                        trackingEventName={trackingEventName}
                        onSortChanged={onSortChanged}
                      />
                    </span>
                  </div>
                )
              }
            </Popper>
          )
        }
      </Manager>
    </span>
  );
}

SortDataActions.propTypes = {
  sortableType: propTypes.oneOf([
    SORT_TYPE_DATE,
    SORT_TYPE_NUMERIC,
    SORT_TYPE_STRING,
    '',
  ]),
  sortParamKey: propTypes.string,
  sortParamString: propTypes.string,
  trackingEventName: propTypes.string,
  onSortChanged: propTypes.func,
};

SortDataActions.defaultProps = {
  sortableType: SORT_TYPE_STRING,
  sortParamKey: '',
  sortParamString: '',
  trackingEventName: 'Show Sorting Actions clicked',
  onSortChanged: undefined,
};
