/* eslint-disable max-len */
import React from 'react';

type AdobeProps = {
  className?: string;
};

export function Adobe({ className = '' } : AdobeProps) {
  return (
    <svg className={`Adobe ${className}`} fill="#fa0c00" viewBox="0 0 54.424461 14.339843">
      <g>
        <path d="M 5.996094,0 H 0 v 14.339843 z m 0,0" />
        <path d="m 10.214844,0 h 5.988281 v 14.339843 z m 0,0" />
        <path d="m 8.105469,5.285156 3.816406,9.054687 H 9.417969 L 8.277344,11.457031 H 5.484375 Z m 0,0" />
      </g>
      <g>
        <path
          d="m 25.985303,9.11922 0.707031,2.027344 c 0.02344,0.05078 0.05859,0.07422 0.121094,0.07422 h 1.359375 c 0.07422,0 0.08594,-0.03516 0.07422,-0.109375 L 25.438428,3.310627 c -0.01172,-0.0625 -0.02344,-0.074219 -0.08594,-0.074219 h -1.6875 c -0.04687,0 -0.07422,0.035156 -0.07422,0.085937 -0.02344,0.410157 -0.05859,0.535157 -0.109375,0.65625 l -2.503906,7.121094 c -0.01172,0.08594 0.01563,0.121094 0.08594,0.121094 h 1.214843 c 0.07422,0 0.109375,-0.02344 0.136719,-0.09766 L 23.082959,9.11922 Z M 23.469678,7.795002 c 0.367187,-1.109375 0.851562,-2.53125 1.046875,-3.34375 h 0.01172 c 0.242188,0.851562 0.8125,2.539062 1.070313,3.34375 z m 0,0"
        />
        <path
          d="m 31.857666,11.341877 c 0.730469,0 1.507813,-0.132813 2.296875,-0.472657 0.0625,-0.02344 0.07422,-0.05078 0.07422,-0.109375 -0.02344,-0.21875 -0.05078,-0.535156 -0.05078,-0.777343 v -7.34375 c 0,-0.046875 0,-0.070313 -0.05859,-0.070313 h -1.324219 c -0.05078,0 -0.07422,0.023438 -0.07422,0.085938 V 5.142658 C 32.513916,5.11922 32.369385,5.107502 32.19751,5.107502 c -2.136719,0 -3.449219,1.410156 -3.449219,3.171875 0,2.042968 1.347656,3.0625 3.109375,3.0625 z m 0.863281,-1.359375 c -0.21875,0.070312 -0.460937,0.097656 -0.707031,0.097656 -0.96875,0 -1.761719,-0.546875 -1.761719,-1.875 0,-1.175781 0.816407,-1.871094 1.898438,-1.871094 0.21875,0 0.410156,0.023438 0.570312,0.085938 z m 0,0"
        />
        <path
          d="m 38.453285,5.107502 c -1.824219,0 -2.953125,1.398437 -2.953125,3.125 0,1.542968 0.898438,3.109375 2.925781,3.109375 1.714844,0 2.917969,-1.261719 2.917969,-3.148438 0,-1.664062 -1.019531,-3.085937 -2.890625,-3.085937 z m -0.07422,1.226562 c 1.03125,0 1.46875,0.886719 1.46875,1.898438 0,1.25 -0.644531,1.871093 -1.394531,1.871093 -0.925781,0 -1.472656,-0.777343 -1.472656,-1.898437 0,-1.152344 0.582031,-1.871094 1.398437,-1.871094 z m 0,0"
        />
        <path
          d="m 42.712968,2.568439 c -0.05078,0 -0.08594,0.023438 -0.08594,0.085938 v 8.3125 c 0,0.03516 0.03516,0.09766 0.08594,0.109375 0.582031,0.179687 1.191406,0.265625 1.820312,0.265625 1.800781,0 3.550781,-1.117188 3.550781,-3.367188 0,-1.628906 -1.117187,-2.867187 -2.867187,-2.867187 -0.402344,0 -0.777344,0.0625 -1.105469,0.171875 L 44.09578,2.666095 c 0,-0.085937 -0.02344,-0.097656 -0.109375,-0.097656 z m 3.875,5.554688 c 0,1.347656 -0.921875,1.980468 -1.917969,1.980468 -0.207031,0 -0.390625,-0.01172 -0.558594,-0.0625 V 6.49422 c 0.191406,-0.074218 0.421875,-0.136718 0.847656,-0.136718 0.960938,0 1.628907,0.609375 1.628907,1.765625 z m 0,0"
        />
        <path
          d="m 53.026024,8.560627 c 0.59375,0 1.082031,-0.011719 1.25,-0.050782 0.0625,-0.011718 0.08594,-0.035156 0.09766,-0.085937 0.03516,-0.132813 0.05078,-0.410156 0.05078,-0.75 0,-1.15625 -0.695312,-2.566406 -2.492187,-2.566406 -1.835938,0 -2.855469,1.496093 -2.855469,3.183593 0,1.496094 0.789063,3.050782 3,3.050782 0.828125,0 1.363281,-0.132813 1.824219,-0.351563 0.04687,-0.02344 0.07031,-0.0625 0.07031,-0.132812 V 9.845783 c 0,-0.058594 -0.03516,-0.070313 -0.07031,-0.046875 -0.460938,0.195312 -0.960938,0.292969 -1.507813,0.292969 -1.238281,0 -1.800781,-0.683594 -1.835937,-1.53125 z m -2.46875,-1.046875 c 0.09766,-0.59375 0.472656,-1.238282 1.3125,-1.238282 0.925781,0 1.167969,0.777344 1.167969,1.128907 0,0.011718 0,0.0625 0,0.097656 -0.05078,0.011719 -0.207032,0.011719 -0.667969,0.011719 z m 0,0"
        />
      </g>
    </svg>
  )
}
