import React from 'react';
import * as propTypes from 'lib/prop_types';
import {
  Button,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from '@user-interviews/ui-design-system';

import { TrackedButton } from 'common/analytics';
import { TrackedModal } from 'common/modals';

// Disabled when adding max-props rule.
// Future work should stay under 6 props
// eslint-disable-next-line local-rules/max-props
export function ConfirmationModal({
  buttonText,
  confirmEvent,
  message,
  openEvent,
  title,
  onConfirm,
  onRequestClose,
}) {
  const isOpen = message && title && onConfirm;

  return (
    <TrackedModal
      event={openEvent}
      isOpen={!!isOpen}
      onRequestClose={onRequestClose}
    >
      <ModalHeader
        title={title}
        titleId="SurveyBuilder-confirm-delete-modal"
        onRequestClose={onRequestClose}
      />
      <ModalBody>
        { message || ''}
      </ModalBody>
      <ModalFooter>
        <Button
          variant="transparent"
          onClick={onRequestClose}
        >
          Cancel
        </Button>
        <TrackedButton
          event={confirmEvent}
          type="button"
          variant="primary"
          onClick={onConfirm}
        >
          {buttonText}
        </TrackedButton>
      </ModalFooter>
    </TrackedModal>
  );
}

ConfirmationModal.propTypes = {
  buttonText: propTypes.string,
  confirmEvent: propTypes.string,
  message: propTypes.node,
  openEvent: propTypes.string,
  title: propTypes.string,
  onConfirm: propTypes.func,
  onRequestClose: propTypes.func,
};

ConfirmationModal.defaultProps = {
  buttonText: 'Confirm',
  confirmEvent: 'Survey -- Action Confirmed',
  message: undefined,
  openEvent: 'Survey -- Modal Opened',
  title: undefined,
  onConfirm: undefined,
  onRequestClose: undefined,
};
