import React, { useCallback, useContext, useMemo } from 'react';
import propTypes from 'prop-types';
import { Droppable } from 'react-beautiful-dnd';

import {
  Accordion,
  AccordionItem,
  AccordionCollapse,
  AccordionToggle,
  Card,
  CardSizes,
} from '@user-interviews/ui-design-system';

import { trackingEvents } from 'lib/analytics';
import { isQuestionGroup } from 'lib/surveys/builder/utilities';

import SurveyContext from '../../context';
import { Question } from '../../question';

import { AddNewPageButton } from './add_new_page_button';
import { SectionControls } from './section_controls';
import { SectionDropdown } from './section_dropdown';

import styles from './section.module.scss';

export function Section({ section }) {
  const {
    allowEmpty,
    isReadonly,
    survey,
    sectionFunctions,
    setModalProps,
  } = useContext(SurveyContext);

  const sectionQuestions = useMemo(() =>
    section.questionIds.map(id => survey.questions[id]), [section, survey.questions]);

  const sectionAnswers = useMemo(() =>
    sectionQuestions.reduce((answerIds, sectionQuestion) => {
      if (sectionQuestion.answerIds) {
        return [...answerIds, ...sectionQuestion.answerIds];
      }

      return answerIds;
    }, []).map(answerId => survey.answers[answerId]), [sectionQuestions, survey.answers]);

  const isRemovableQuestion = section.questionIds.length > 1;

  const removeable = !isReadonly && (allowEmpty || survey.sectionIds.length > 1);

  const hasSkipLogicErrors = !!section.skipLogicIds.find((id) => {
    const skipLogic = survey.skipLogics[id];
    if (Object.keys(skipLogic.errors).length) return true;

    return skipLogic.conditionIds.find(
      conditionId => Object.keys(survey.skipLogicConditions[conditionId].errors).length,
    );
  });

  const hasSkipLogic = section.skipLogicIds.length > 0;

  const removeMessage = () => {
    if (!hasSkipLogic) return 'Deleting the page will also delete all questions inside the page.';

    return (
      <div>
        You may need to adjust your skip logic.
        Any skip logic that pointed to this page will now point to the end of the screener.
        <br />
        <br />
        Deleting the page will also delete all questions inside the page.
      </div>
    );
  };

  const handleRemove = useCallback(() => {
    setModalProps({
      buttonText: 'Confirm delete',
      title: 'Are you sure you want to delete this page?',
      message: removeMessage(),
      openEvent: trackingEvents.SURVEY_DELETE_PAGE_MODAL_SHOWN,
      confirmEvent: trackingEvents.SURVEY_DELETE_PAGE_CONFIRM_CLICKED,
      onConfirm: () => sectionFunctions.remove(section.uuid),
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setModalProps, sectionFunctions, section.uuid]);

  const handleAddSection = useCallback(
    () => sectionFunctions.add(section.uuid),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sectionFunctions.add, section.uuid],
  );

  const handleDuplicateSection = useCallback(
    () => sectionFunctions.duplicate(section.uuid),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sectionFunctions.duplicate, section.uuid],
  );

  const sectionQuestionCount = section.questionIds?.length;
  const questionText = sectionQuestionCount === 1 ? 'Question' : 'Questions';
  const helperText = `| ${sectionQuestionCount} ${questionText}`;

  return (
    <>
      <Droppable droppableId={section.uuid}>
        {provided => (
          <Card
            className={styles.SurveyBuilderSection}
            noPadding
            size={CardSizes.LARGE}
          >
            {!isReadonly && (
              <SectionDropdown
                className={styles.SectionDropdown}
                removeable={removeable}
                onDuplicate={handleDuplicateSection}
                onRemove={handleRemove}
              />
            )}
            <Accordion defaultActiveKey={section.uuid} flush>
              <AccordionItem borderless>
                <AccordionToggle
                  chevronLateral
                  chevronLeft
                  collapsedText={helperText}
                  eventKey={section.uuid}
                  title={section.title}
                />
                <AccordionCollapse eventKey={section.uuid}>
                  <div
                    className="form-section"
                    ref={provided.innerRef}
                  >
                    {hasSkipLogicErrors && (
                      <div className={styles.errors}>
                        There were errors with the skip logic for this section
                      </div>
                    )}

                    {section.questionIds.map((questionId, index) => {
                      // eslint-disable-next-line no-use-before-define
                      const questionSkipLogics = getSkipLogicsInvolvingQuestion(questionId, section.uuid, survey);

                      return (
                        <Question
                          isRemovableQuestion={isRemovableQuestion}
                          key={questionId}
                          pageTitle={section.title}
                          position={index}
                          question={survey.questions[questionId]}
                          questionSkipLogics={questionSkipLogics}
                          screenerSections={Object.values(survey.sections)}
                          sectionAnswers={sectionAnswers}
                          sectionQuestions={sectionQuestions}
                          sectionUUID={section.uuid}
                        />
                      );
                    })}
                    {provided.placeholder}

                    <SectionControls
                      hasSkipLogic={hasSkipLogic}
                      section={section}
                    />
                  </div>
                </AccordionCollapse>
              </AccordionItem>
            </Accordion>
          </Card>
        )}
      </Droppable>

      {!isReadonly && <AddNewPageButton onAddSection={handleAddSection} />}
    </>
  );
}

Section.propTypes = {
  section: propTypes.object.isRequired,
};

const getSkipLogicsInvolvingQuestion = (questionId, sectionId, survey) => {
  const section = survey.sections[sectionId];
  const question = survey.questions[questionId];

  const questionIds = isQuestionGroup(question) ? question.questionIds : [questionId];

  const skipLogicsInvolvingThisQuestion = [];

  section.skipLogicIds.forEach((skipLogicId) => {
    let skipLogic = survey.skipLogics[skipLogicId];
    let hasConditionsInvolvingThisQuestion = false;

    const conditions = skipLogic.conditionIds
      .filter(conditionId => survey.skipLogicConditions[conditionId] !== undefined)
      .map((conditionId) => {
        const condition = survey.skipLogicConditions[conditionId];

        if (!hasConditionsInvolvingThisQuestion && questionIds.includes(condition.questionId)) {
          hasConditionsInvolvingThisQuestion = true;
        }
        return condition;
      });

    if (hasConditionsInvolvingThisQuestion) {
      skipLogic = {
        ...skipLogic,
        skipLogicConditions: conditions,
      };
      skipLogicsInvolvingThisQuestion.push(skipLogic);
    }
  });

  return skipLogicsInvolvingThisQuestion;
};
