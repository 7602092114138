import React from 'react';
import propTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { TrackedButton } from 'common/analytics';
import { faCheck } from 'lib/font_awesome/solid';

import {
  SORT_DIRECTION_ASCENDING,
  SORT_DIRECTION_DESCENDING,
} from '../../../lib/constants/sort_data_actions_constants';

export function SortControl({
  active,
  sortDirection,
  text,
  trackingEvent,
  trackingEventData,
  onControlClick,
}) {
  return (
    <TrackedButton
      className="SortControl"
      event={trackingEvent}
      eventData={trackingEventData}
      variant="transparent"
      onClick={() => onControlClick(sortDirection, active)}
    >
      {active && (
      <FontAwesomeIcon
        className="ColumnActions__icon"
        icon={faCheck}
      />
      )}
      { text }
    </TrackedButton>
  )
}

SortControl.propTypes = {
  active: propTypes.bool.isRequired,
  sortDirection: propTypes.oneOf([
    SORT_DIRECTION_ASCENDING,
    SORT_DIRECTION_DESCENDING,
  ]).isRequired,
  text: propTypes.string.isRequired,
  trackingEvent: propTypes.string.isRequired,
  trackingEventData: propTypes.object.isRequired,
  onControlClick: propTypes.func.isRequired,
};
