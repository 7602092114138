import React from 'react';
import * as propTypes from 'lib/prop_types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarAlt,
  faDotCircle,
  faFont,
  faHashtag,
} from 'lib/font_awesome/solid';

const iconMap = {
  string: faFont,
  pick_one: faFont,
  pick_any: faFont,
  boolean: faDotCircle,
  date: faCalendarAlt,
  decimal: faHashtag,
};

export function SurveyFormQuestionIconLabel({ attribute }) {
  return (
    <span className="FormQuestionIconLabel">
      <FontAwesomeIcon
        aria-label={attribute.type}
        className="icon-left"
        icon={iconMap[attribute.type]}
      />
      {attribute.name}
    </span>
  );
}

SurveyFormQuestionIconLabel.propTypes = {
  attribute: propTypes.shape({
    name: propTypes.string,
    type: propTypes.string,
  }).isRequired,
};
