import React, { Component } from 'react';
import * as propTypes from 'lib/prop_types';
import classnames from 'classnames';

import { IconButton, ProfileCell } from '@user-interviews/ui-design-system';

import { formatDateTimeFromIso8601 } from 'lib/formatters/date_time';

import { Body } from './body';
import { Edit } from './edit';
import './comment.scss';

export class Comment extends Component {
  static propTypes = {
    comment: propTypes.shape({
      anchor: propTypes.string,
      body: propTypes.string.isRequired,
      canDelete: propTypes.bool.isRequired,
      canUpdate: propTypes.bool.isRequired,
      commenter: propTypes.shape({
        email: propTypes.string.isRequired,
        fullName: propTypes.string.isRequired,
        id: propTypes.string.isRequired,
      }).isRequired,
      createdAt: propTypes.string.isRequired,
      id: propTypes.string.isRequired,
    }).isRequired,
    commenters: propTypes.object,
    focused: propTypes.bool,
    inviteDomain: propTypes.string,
    onDelete: propTypes.func.isRequired,
    onInvite: propTypes.func,
    onUpdate: propTypes.func.isRequired,
  };

  static defaultProps = {
    commenters: {},
    focused: false,
    inviteDomain: undefined,
    onInvite: undefined,
  };

  state = { editing: false };

  handleDelete = (event) => {
    event.preventDefault();

    this.props.onDelete(this.props.comment);
  };

  handleUpdate = async (body) => {
    if (!body) return;
    const comment = {
      ...this.props.comment,
      body,
    };

    await this.props.onUpdate(comment);
    this.setState({ editing: false });
  };

  startEditing = (event) => {
    event.preventDefault();

    this.setState({ editing: true });
  };

  stopEditing = (event) => {
    event.preventDefault();

    this.setState({ editing: false });
  };

  get classNames() {
    return classnames('Comment', {
      'Comment--focus': this.props.focused,
    });
  }

  get commenterId() {
    return this.props.comment.commenter.id;
  }

  get formattedDate() {
    return formatDateTimeFromIso8601(
      this.props.comment.createdAt,
      {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      },
    );
  }

  renderControls() {
    if (this.state.editing) return null;

    return (
      <div className="Comment__controls">
        {this.props.comment.canUpdate && (
          <IconButton
            action="EDIT"
            ariaLabel="Edit project note"
            size="sm"
            onClick={this.startEditing}
          />
        )}
        {this.props.comment.canDelete && (
          <IconButton
            action="DELETE"
            ariaLabel="Delete project note"
            size="sm"
            onClick={this.handleDelete}
          />
        )}
      </div>
    );
  }

  renderBody() {
    if (this.state.editing) {
      return (
        <Edit
          comment={this.props.comment}
          commenters={this.props.commenters}
          inviteDomain={this.props.inviteDomain}
          onCancel={this.stopEditing}
          onInvite={this.props.onInvite}
          onSave={this.handleUpdate}
        />
      );
    }

    return <Body comment={this.props.comment} commenters={this.props.commenters} />;
  }

  render() {
    return (
      <li className={this.classNames} id={`comment-${this.props.comment.id}`}>
        <div className="Comment__header">
          <ProfileCell
            colorId={this.commenterId}
            subtitle={this.formattedDate}
            user={this.props.comment.commenter}
          />
          {this.renderControls()}
        </div>

        {this.renderBody()}
      </li>
    );
  }
}
