import React, { useCallback, useContext } from 'react';
import propTypes from 'prop-types';

import SurveyContext from '../../../context';
import { surveyPropType } from '../../../prop_types';

import { ConditionSelect } from './condition_select';
import { Actions } from './actions';
import { Controls } from './controls';

export function ConditionRow({
  isReadonly,
  position,
  skipLogic,
  skipLogicCondition,
  skipLogicOptions,
  survey,
}) {
  const {
    skipLogicFunctions: {
      update: updateSkipLogic,
      updateCondition: updateSkipLogicCondition,
    },
  } = useContext(SurveyContext);

  const skipLogicId = skipLogic.uuid;

  const handleSkipLogicUpdate = useCallback(changes => (
    updateSkipLogic(skipLogicId, changes)
  ), [skipLogicId, updateSkipLogic]);

  const handleConditionUpdate = useCallback(changes => (
    updateSkipLogicCondition(skipLogicCondition.uuid, changes)
  ), [skipLogicCondition.uuid, updateSkipLogicCondition]);

  return (
    <div className="SkipLogicModal__skip-logic__row">
      <ConditionSelect
        isReadonly={isReadonly}
        joinMethod={skipLogic.joinMethod}
        position={position}
        onUpdate={handleSkipLogicUpdate}
      />

      <Controls
        isReadonly={isReadonly}
        skipLogicCondition={skipLogicCondition}
        skipLogicOptions={skipLogicOptions}
        survey={survey}
        onUpdate={handleConditionUpdate}
      />

      <Actions
        isReadonly={isReadonly}
        skipLogicCondition={skipLogicCondition}
        skipLogicId={skipLogic.uuid}
      />
    </div>
  );
}

ConditionRow.propTypes = {
  isReadonly: propTypes.bool.isRequired,
  position: propTypes.number.isRequired,
  skipLogic: propTypes.shape({
    joinMethod: propTypes.string.isRequired,
    uuid: propTypes.string.isRequired,
  }).isRequired,
  skipLogicCondition: propTypes.object.isRequired,
  skipLogicOptions: propTypes.array.isRequired,
  survey: surveyPropType.isRequired,
};
