import React from 'react';
import awesomeDebouncePromise from 'awesome-debounce-promise';
import { AsyncSelect, Input } from '@user-interviews/ui-design-system';

import useHttp from 'hooks/use_http';

import { ErrorRenderer } from 'lib/errors';
import { unmoderatedProviders } from 'lib/generated_constants/integrations';
import * as propTypes from 'lib/prop_types';
import * as routes from 'lib/routes';

const SEARCH_DEBOUNCE = 750;

function TaskSelect({
  accountId,
  className,
  disabled,
  provider,
  value,
  onChange,
}) {
  const { get, loading } = useHttp();

  const loadOptionsDebounced = awesomeDebouncePromise(
    async (title) => {
      const options = await get(
        routes.api_account_integration_tasks_path(
          accountId,
          provider,
          { filter: { title } },
        ),
        {
          onError: () => {
            ErrorRenderer.alert(
              {
                message: 'We are unable to fetch your tasks. If this problem persists please ' +
              ' email projects@userinterviews.com. In the meantime,' +
              ' you can select "Add a link manually" and paste your link to proceed.',
              },
            );
          },
        },
      );

      return options.map(option => ({
        externalId: option.id,
        label: option.title,
        title: option.title,
        url: option.url,
        value: option.id,
      }));
    },
    SEARCH_DEBOUNCE,
  );

  // react-select needs the whole option object to know which option is selected :|
  const selected = value.externalId ? { label: value.title, value: value.externalId } : null;

  return (
    <>
      <AsyncSelect
        aria-label="External task"
        className={className}
        defaultOptions
        disabled={disabled}
        isLoading={loading}
        loadOptions={loadOptionsDebounced}
        name="externalId"
        options={[selected]}
        placeholder="Search or select a survey..."
        value={selected}
        onChange={onChange}
      />
      <Input
        name="title"
        type="hidden"
        value={value?.title || ''}
      />
      <Input
        name="url"
        type="hidden"
        value={value?.url || ''}
      />
    </>
  );
}

TaskSelect.propTypes = {
  accountId: propTypes.string.isRequired,
  className: propTypes.string,
  disabled: propTypes.bool,
  provider: propTypes.oneOf(unmoderatedProviders).isRequired,
  value: propTypes.object,
  onChange: propTypes.func.isRequired,
};

TaskSelect.defaultProps = {
  className: undefined,
  disabled: false,
  value: undefined,
};

export default TaskSelect;
