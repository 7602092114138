import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';

import * as Constants from '../../../../constants';

export function OperatorSelect({
  isReadonly,
  questionType,
  skipLogicCondition,
  onUpdate,
}) {
  const handleChange = event => onUpdate({ operator: event.target.value });

  return (
    <select
      className={classNames(
        'form-select',
        { 'is-invalid': skipLogicCondition.errors.operator },
      )}
      disabled={isReadonly}
      value={skipLogicCondition.operator || ''}
      onChange={handleChange}
    >
      <option value={Constants.CONDITION_OPERATOR_EQL}>
        {questionType === Constants.QUESTION_PICK_ANY ? 'is chosen' : 'is'}
      </option>
      <option value={Constants.CONDITION_OPERATOR_NOT_EQL}>
        {questionType === Constants.QUESTION_PICK_ANY ? 'is not chosen' : 'is not'}
      </option>
    </select>
  );
}

OperatorSelect.propTypes = {
  isReadonly: propTypes.bool.isRequired,
  questionType: propTypes.string.isRequired,
  skipLogicCondition: propTypes.object.isRequired,
  onUpdate: propTypes.func.isRequired,
};
