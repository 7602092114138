import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Button } from '@user-interviews/ui-design-system';

import * as Keycodes from 'lib/keycodes';

import './tag_list_filter.scss';

export default class TagListFilter extends PureComponent {
  static propTypes = {
    baseClass: PropTypes.string,
    handleFilterClick: PropTypes.func,
    handleResetClick: PropTypes.func,
    placeholder: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    baseClass: undefined,
    placeholder: 'Enter a tag',
    tags: [],
    handleFilterClick: undefined,
    handleResetClick: undefined,
  };

  state = { tagInput: '' };

  handleAddTagClick = () => this.addTag();

  handleRemoveTagClick = event => this.removeTag(event.currentTarget.dataset.tagValue);

  handleTagInputChange = (event) => {
    this.setState({ tagInput: event.target.value });
  };

  handleAddKeyUp = (event) => {
    if (event.keyCode !== Keycodes.ENTER) return null;

    return this.addTag();
  };

  handleRemoveKeyUp = (event) => {
    if (event.keyCode !== Keycodes.ENTER) return null;

    return this.removeTag(event.target.dataset.tagValue);
  };

  get actionClasses() {
    return classnames({
      [`${this.props.baseClass}__actions`]: !!this.props.baseClass,
    }, 'TagListFilter__actions');
  }

  get containerClasses() {
    return classnames({
      [`${this.props.baseClass}`]: !!this.props.baseClass,
    }, 'TagListFilter');
  }

  get inputContainerClasses() {
    return classnames({
      [`${this.props.baseClass}__input-container`]: !!this.props.baseClass,
    }, 'TagListFilter__input-container');
  }

  get listClasses() {
    return classnames({
      [`${this.props.baseClass}__list`]: !!this.props.baseClass,
    }, 'TagListFilter__list');
  }

  get listItemClasses() {
    return classnames({
      [`${this.props.baseClass}__list-item`]: !!this.props.baseClass,
    }, 'TagListFilter__list-item');
  }

  get tags() {
    return this.props.tags;
  }

  get validTagInput() {
    return this.state.tagInput.trim().length > 0;
  }

  addTag() {
    if (!this.validTagInput) return null;

    const tags = [...this.tags];
    tags.push(this.state.tagInput.trim());

    return this.setState({ tagInput: '' }, () => this.props.onChange([...new Set(tags)]));
  }

  removeTag(tag) {
    const tags = this.tags.filter(existing => existing !== tag);

    this.props.onChange(tags);
  }

  render() {
    return (
      <div className={this.containerClasses}>
        <div className="input-group">
          <div className={this.inputContainerClasses}>
            <input
              placeholder={`${this.props.placeholder}`}
              type="text"
              value={this.state.tagInput}
              onChange={this.handleTagInputChange}
              onKeyUp={this.handleAddKeyUp}
            />
            <i
              className="fas fa-plus"
              role="button"
              tabIndex={0}
              onClick={this.handleAddTagClick}
              onKeyUp={this.handleAddKeyUp}
            />
          </div>

          <div className={this.actionClasses}>
            {this.props.handleFilterClick && (
              <Button
                variant="primary"
                onClick={this.props.handleFilterClick}
              >
                Filter
              </Button>
            )}
            {this.props.handleResetClick && (
              <button
                className="btn btn-neutral"
                type="button"
                onClick={this.props.handleResetClick}
              >
                Reset
              </button>
            )}
          </div>
        </div>

        <ul className={this.listClasses}>
          {this.tags.map(tag => (
            <li className={this.listItemClasses} key={tag}>
              {tag}
              <i
                className="fas fa-xmark"
                data-tag-value={tag}
                role="button"
                tabIndex={0}
                onClick={this.handleRemoveTagClick}
                onKeyUp={this.handleRemoveKeyUp}
              />
            </li>
          ))}
        </ul>
      </div>
    );
  }
}
