import React from 'react';

import { usePageTitle } from 'hooks/use_page_title';

type TitledPageProps = {
  children?: React.ReactNode,
  title?: string,
}

// This will append ' | User Interviews' to all HTML titles and update on load
// please do not set `document.title` outside of this if possible
export function TitledPage({
  children,
  title,
}: TitledPageProps) {
  usePageTitle(title);

  // Ignore this error as we use this to make the type correct.
  // Apparently components do not return ReactNode but that is
  // what we expect for `children` so we can't return just `children` here.
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
