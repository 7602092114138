/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import propTypes from 'prop-types';
import memoizeOne from 'memoize-one';

import {
  track,
  trackingEvents,
  trackingPropsShape,
} from 'lib/analytics';

import {
  buildSkipLogic,
  buildSkipLogicCondition,
  insertIntoArray,
} from 'lib/surveys/builder/utilities';

import { AnswerUtilities } from 'lib/surveys/builder/answer_utilities';
import { QuestionUtilities } from 'lib/surveys/builder/question_utilities';
import { QuestionGroupUtilities } from 'lib/surveys/builder/question_group_utilities';
import { SectionUtilities } from 'lib/surveys/builder/section_utilities';
import { SkipLogicConditionUtilities } from 'lib/surveys/builder/skip_logic_condition_utilities';
import { SkipLogicUtilities } from 'lib/surveys/builder/skip_logic_utilities';

import { OPT_IN_FORM_TYPE, SCREENER_FORM_TYPE } from 'common/components/survey_builder/constants';
import { ConfirmationModal } from './confirmation_modal';
import { surveyPropType } from './prop_types';
import { SkipLogicModal } from './skip_logic_modal';
import SurveyUpgradeModal from './survey_upgrade_modal';
import Sections from './sections';

import SurveyContext from './context';

import './survey_builder.scss';

class SurveyBuilder extends Component {
  state = {
    checkPremium: this.props.checkPremium,
    upgradeModalIsOpen: false,
    modalProps: {
      buttonText: undefined,
      confirmEvent: undefined,
      message: undefined,
      openEvent: undefined,
      title: undefined,
      onConfirm: undefined,
    },
    surveyDuplicateBeforeEdit: undefined,
  };

  getMemoizedSurveyContextValue = memoizeOne((contextProps, formType) => ({
    ...contextProps,
    formType,
  }));

  handleAddAnswers = (questionUUID, answerUUID, answers) => {
    this.props.tracking.trackEvent({ event: trackingEvents.SURVEY_ANSWER_BATCH_ADDED });

    const updates = AnswerUtilities.addBatch(
      questionUUID,
      answerUUID,
      answers,
      this.survey,
    );

    this.updateSurvey(updates);
  };

  handleAddAnswer = (questionUUID, prevAnswerUUID) => {
    const updates = AnswerUtilities.add(questionUUID, prevAnswerUUID, this.survey);

    this.updateSurvey(updates);
  };

  handleAddOtherAnswer = (questionUUID) => {
    const updates = AnswerUtilities.addOther(questionUUID, this.survey);

    this.updateSurvey(updates);
  };

  handleMoveAnswer = (questionId, activeAnswerId, overAnswerId) => {
    this.props.tracking.trackEvent({ event: trackingEvents.SURVEY_ANSWER_MOVED });

    const updates = AnswerUtilities.move(questionId, activeAnswerId, overAnswerId, this.survey);

    this.updateSurvey(updates);
  };

  handleAddQuestion = (sectionUUID, prevQuestionUUID) => {
    const update = QuestionUtilities.add(sectionUUID, prevQuestionUUID, this.survey);

    this.updateSurvey(update);
  };

  handleAddSection = (prevSectionUUID) => {
    const updates = this.updatedSections(SectionUtilities.add, prevSectionUUID);

    this.updateSurvey(updates);
  };

  handleDuplicateSection = (sectionUUID) => {
    const updates = this.updatedSections(SectionUtilities.duplicate, sectionUUID);

    this.updateSurvey(updates);
  };

  handleRemoveSection = (sectionUUID) => {
    const changes = this.updatedSections(SectionUtilities.remove, sectionUUID)
    this.handleUnsetModalProps();

    this.updateSurvey(changes);
  };

  updatedSections = (updateFn, sectionIdentifier) => updateFn(sectionIdentifier, this.survey, this.props.formType)

  handleAddSectionClicked = (prevSectionUUID) => {
    if (this.survey.sectionIds.length === 0) {
      this.handleAddSection();
    } else {
      this.requestPremiumAction(this.handleAddSection, [prevSectionUUID]);
    }
  };

  handleDuplicateSectionClicked = (sectionUUID) => {
    this.requestPremiumAction(this.handleDuplicateSection, [sectionUUID]);
  };

  handleAddSkipLogic = (sectionUUID) => {
    const section = { ...this.survey.sections[sectionUUID] };
    const newSkipLogic = buildSkipLogic();
    section.skipLogicIds = insertIntoArray(
      section.skipLogicIds,
      newSkipLogic.uuid,
    );

    const newSkipLogicCondition = buildSkipLogicCondition();
    newSkipLogic.conditionIds.push(newSkipLogicCondition.uuid);

    this.updateSurvey({
      sections: { ...this.survey.sections, [section.uuid]: section },
      skipLogicConditions: {
        ...this.survey.skipLogicConditions,
        [newSkipLogicCondition.uuid]: newSkipLogicCondition,
      },
      skipLogics: { ...this.survey.skipLogics, [newSkipLogic.uuid]: newSkipLogic },
    });
  };

  handleAddSkipLogicCondition = (skipLogicUUID, prevConditionUUID) => {
    const skipLogic = { ...this.survey.skipLogics[skipLogicUUID] };
    const newSkipLogicCondition = buildSkipLogicCondition();

    skipLogic.conditionIds = insertIntoArray(
      skipLogic.conditionIds,
      newSkipLogicCondition.uuid,
      prevConditionUUID,
    );

    this.updateSurvey({
      skipLogicConditions: {
        ...this.survey.skipLogicConditions,
        [newSkipLogicCondition.uuid]: newSkipLogicCondition,
      },
      skipLogics: { ...this.survey.skipLogics, [skipLogic.uuid]: skipLogic },
    });
  };

  setSurveyDuplicateBeforeEdit = () => {
    this.setState({
      surveyDuplicateBeforeEdit: this.survey,
    });
  };

  handleCloseSkipLogic = () => {
    this.setState({
      skipLogicSectionId: undefined,
      surveyDuplicateBeforeEdit: undefined,
    });
  };

  handleDuplicateQuestion = (sectionUUID, questionUUID) => {
    const updates = QuestionUtilities.duplicate(sectionUUID, questionUUID, this.survey);

    this.updateSurvey(updates);
  };

  handleMoveQuestion = ({ destination, source, draggableId }) => {
    if (!destination) return false;

    if (destination.droppableId === source.droppableId) {
      const updates = QuestionUtilities.moveWithinSection(destination, draggableId, this.survey);

      return this.updateSurvey(updates);
    }

    const skipLogicConditions = Object.values(this.survey.skipLogicConditions).filter(
      condition => (condition && condition.questionId === draggableId),
    );

    if (skipLogicConditions.length > 0) {
      const numSkipConditions = skipLogicConditions.length;

      return this.handleSetModalProps({
        buttonText: 'Confirm move',
        title: 'Are you sure you want to move this question?',
        message: (
          <div>
            Moving this question across a page means that
            <strong>
              &nbsp;{numSkipConditions} skip logic
              {numSkipConditions > 1 ? ' conditions' : ' condition'} will be removed.
            </strong>
            &nbsp;Skip logic statements that do not reference this question will be unaffected.
          </div>
        ),
        confirmEvent: trackingEvents.SURVEY_MOVE_QUESTION_CONFIRM_CLICKED,
        openEvent: trackingEvents.SURVEY_MOVE_QUESTION_MODAL_SHOWN,
        onConfirm: () => this.handleMoveQuestionFromSection(source, destination, draggableId),
      });
    }

    return this.handleMoveQuestionFromSection(source, destination, draggableId);
  };

  trackQuestionMoved = (targetSection, currentSection) => {
    const { tracking } = this.props;

    tracking.trackEvent(
      {
        event: trackingEvents.SURVEY_QUESTION_MOVED_FROM_PAGE,
        screener_section_target: targetSection.title,
        screener_section_current: currentSection.title,
      },
    );
  }

  // TODO: RS-20454 Moving into QuestionUtilities
  handleMoveQuestionFromSection = (source, destination, questionId) => {
    let survey = { ...this.survey };

    const currentSection = survey.sections[source.droppableId];
    const targetSection = survey.sections[destination.droppableId];

    this.trackQuestionMoved(targetSection, currentSection);

    const currentPosition = currentSection.questionIds.indexOf(questionId);
    const question = currentSection.questionIds.splice(currentPosition, 1)[0];

    // Move question
    targetSection.questionIds.splice(destination.index, 0, question);

    // Remove question's skip logic conditions
    const skipLogicUpdates = SkipLogicConditionUtilities.removeQuestionConditions(currentSection.uuid, questionId, survey);
    survey = { ...survey, ...skipLogicUpdates };

    // Remove origin section if last question
    if (currentSection.questionIds.length === 0) {
      survey = {
        ...survey,
        ...this.updatedSections(SectionUtilities.remove, currentSection.uuid),
      };
    }

    this.handleUnsetModalProps();

    this.updateSurvey({
      sections: survey.sections,
      sectionIds: survey.sectionIds,
      skipLogics: survey.skipLogics,
      skipLogicConditions: survey.skipLogicConditions,
    });
  };

  handleRemoveSkipLogic = (sectionUUID, skipLogicUUID) => {
    const updates = SkipLogicUtilities.remove(sectionUUID, skipLogicUUID, this.survey);

    this.updateSurvey(updates);
  };

  handleRemoveSkipLogicCondition = (skipLogicUUID, skipLogicConditionUUID) => {
    const updates = SkipLogicConditionUtilities.removeConditions(skipLogicUUID, [skipLogicConditionUUID], this.survey);

    this.updateSurvey(updates);
  };

  handleEditSkipLogicClicked = (sectionUUID) => {
    this.requestPremiumAction(this.handleOpenSkipLogic, [sectionUUID]);
  };

  handleCancelSkipLogicEdit = () => {
    this.updateSurvey({
      sections: {
        ...this.state.surveyDuplicateBeforeEdit.sections,
      },
      skipLogicConditions: {
        ...this.state.surveyDuplicateBeforeEdit.skipLogicConditions,
      },
      skipLogics: {
        ...this.state.surveyDuplicateBeforeEdit.skipLogics,
      },
    });

    this.handleCloseSkipLogic();
  };

  handleOpenSkipLogic = (sectionUUID) => {
    this.setState({ skipLogicSectionId: sectionUUID });
  };

  handleRemoveAnswer = (questionUUID, answerUUID) => {
    const updates = AnswerUtilities.remove(questionUUID, answerUUID, this.survey);

    this.updateSurvey(updates);
  };

  handleRemoveQuestion = (sectionUUID, questionUUID) => {
    const update = QuestionUtilities.remove(sectionUUID, questionUUID, this.survey);

    this.updateSurvey(update);
  };

  handleSetModalProps = (modalProps) => {
    this.setState({ modalProps });
  };

  handleUnsetModalProps = () => {
    this.setState({
      modalProps: {
        buttonText: undefined,
        confirmEvent: undefined,
        message: undefined,
        openEvent: undefined,
        title: undefined,
        onConfirm: undefined,
      },
    });
  };

  handleUpdateAnswer = (answerUUID, update) => {
    const updates = AnswerUtilities.update(answerUUID, update, this.survey);

    this.updateSurvey(updates);
  };

  handleUpdateQuestion = (questionUUID, updates) => {
    const update = QuestionUtilities.update(questionUUID, updates, this.survey);

    this.updateSurvey(update);
  };

  handleUpdateQuestionType = (questionUUID, questionType) => {
    const update = QuestionUtilities.updateType(questionUUID, questionType, this.survey);

    this.updateSurvey(update);
  };

  handleAddQuestionColumn = (questionGroupUUID) => {
    const update = QuestionGroupUtilities.addColumn(questionGroupUUID, this.survey);

    this.updateSurvey(update);
  };

  handleAddQuestionRow = (questionGroupUUID) => {
    const update = QuestionGroupUtilities.addRow(questionGroupUUID, this.survey);

    this.updateSurvey(update);
  };

  handleRemoveQuestionColumn = (questionGroupUUID, answerIndex) => {
    const update = QuestionGroupUtilities.removeColumn({ questionGroupUUID, answerIndex, survey: this.survey });

    this.updateSurvey(update);
  };

  handleRemoveQuestionRow = (questionGroupUUID, questionUUID) => {
    const update = QuestionGroupUtilities.removeRow({ questionGroupUUID, questionUUID, survey: this.survey });

    this.updateSurvey(update);
  };

  handleUpdateQuestionColumn = (questionGroupUUID, answerIndex, updates) => {
    const update = QuestionGroupUtilities.updateColumn({
      questionGroupUUID,
      answerIndex,
      updates,
      survey: this.survey,
    });

    this.updateSurvey(update);
  };

  handleUpdateSkipLogic = (skipLogicUUID, updates) => {
    this.updateSurvey({
      skipLogics: {
        ...this.survey.skipLogics,
        [skipLogicUUID]: {
          ...this.survey.skipLogics[skipLogicUUID],
          ...updates,
          errors: {},
        },
      },
    });
  };

  handleUpdateSkipLogicCondition = (skipLogicConditionUUID, updates) => {
    this.updateSurvey({
      skipLogicConditions: {
        ...this.survey.skipLogicConditions,
        [skipLogicConditionUUID]: {
          ...this.survey.skipLogicConditions[skipLogicConditionUUID],
          ...updates,
          errors: {},
        },
      },
    });
  };

  handleUpgradeModalUpgradeClick = () => {
    const [callback, args] = this.state.requestedPremiumAction;
    this.setState({ checkPremium: false, requestedPremiumAction: undefined });

    callback.apply(this, args);
    this.setState({ upgradeModalIsOpen: false });
  };

  handleUpgradeModalRequestClose = () => {
    this.props.tracking.trackEvent({ event: trackingEvents.SURVEY_UPGRADE_DISMISSED });
    this.setState({ upgradeModalIsOpen: false });
  };

  determineQuestionType = (question) => QuestionUtilities.determineType(question, this.survey);

  get contextProps() {
    return {
      allowEmpty: this.props.allowEmpty,
      isReadonly: this.props.isReadonly,
      survey: this.survey,
      setModalProps: this.handleSetModalProps,
      setSurveyDuplicateBeforeEdit: this.setSurveyDuplicateBeforeEdit,
      setToastMessage: this.props.setToastMessage,
      answerFunctions: {
        add: this.handleAddAnswer,
        addBatch: this.handleAddAnswers,
        addOther: this.handleAddOtherAnswer,
        move: this.handleMoveAnswer,
        remove: this.handleRemoveAnswer,
        update: this.handleUpdateAnswer,
      },
      sectionFunctions: {
        add: this.handleAddSectionClicked,
        duplicate: this.handleDuplicateSectionClicked,
        remove: this.handleRemoveSection,
      },
      questionFunctions: {
        add: this.handleAddQuestion,
        determineType: this.determineQuestionType,
        duplicate: this.handleDuplicateQuestion,
        move: this.handleMoveQuestion,
        remove: this.handleRemoveQuestion,
        update: this.handleUpdateQuestion,
        updateType: this.handleUpdateQuestionType,
      },
      questionGroupFunctions: {
        addColumn: this.handleAddQuestionColumn,
        addRow: this.handleAddQuestionRow,
        removeColumn: this.handleRemoveQuestionColumn,
        removeRow: this.handleRemoveQuestionRow,
        updateColumn: this.handleUpdateQuestionColumn,
      },
      skipLogicFunctions: {
        add: this.handleAddSkipLogic,
        addCondition: this.handleAddSkipLogicCondition,
        removeCondition: this.handleRemoveSkipLogicCondition,
        updateCondition: this.handleUpdateSkipLogicCondition,
        edit: this.handleEditSkipLogicClicked,
        remove: this.handleRemoveSkipLogic,
        update: this.handleUpdateSkipLogic,
      },
    };
  }

  get survey() {
    return this.props.survey;
  }

  requestPremiumAction(callback, args = []) {
    if (this.state.checkPremium) {
      this.setState({ requestedPremiumAction: [callback, args], upgradeModalIsOpen: true });
    } else {
      callback.apply(this, args);
    }
  }

  updateSurvey(updates) {
    this.props.onUpdateSurvey(updates);
  }

  render() {
    const contextValue = this.getMemoizedSurveyContextValue(this.contextProps, this.props.formType);

    return (
      <div className="SurveyBuilder form-well">
        <ConfirmationModal
          buttonText={this.state.modalProps.buttonText}
          confirmEvent={this.state.modalProps.confirmEvent}
          message={this.state.modalProps.message}
          openEvent={this.state.modalProps.openEvent}
          title={this.state.modalProps.title}
          onConfirm={this.state.modalProps.onConfirm}
          onRequestClose={this.handleUnsetModalProps}
        />
        <SurveyContext.Provider value={contextValue}>
          <SkipLogicModal
            isReadonly={this.props.isReadonly}
            sectionId={this.state.skipLogicSectionId}
            survey={this.survey}
            onRequestCancel={this.handleCancelSkipLogicEdit}
            onRequestClose={this.handleCloseSkipLogic}
          />

          <SurveyUpgradeModal
            isOpen={this.state.upgradeModalIsOpen}
            onRequestClose={this.handleUpgradeModalRequestClose}
            onUpgradeClick={this.handleUpgradeModalUpgradeClick}
          />

          <Sections survey={this.survey} />
        </SurveyContext.Provider>
      </div>
    );
  }
}

SurveyBuilder.propTypes = {
  allowEmpty: propTypes.bool,
  checkPremium: propTypes.bool.isRequired,
  formType: propTypes.oneOf([OPT_IN_FORM_TYPE, SCREENER_FORM_TYPE]).isRequired,
  isReadonly: propTypes.bool,
  setToastMessage: propTypes.func,
  survey: surveyPropType.isRequired,
  onUpdateSurvey: propTypes.func.isRequired,
  ...trackingPropsShape,
};

SurveyBuilder.defaultProps = {
  allowEmpty: true,
  isReadonly: false,
};

export default track()(SurveyBuilder);
