import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, MODAL_SIZES, SingleSelect,
} from '@user-interviews/ui-design-system';

import { QUESTION_TYPE_BOOLEAN, QUESTION_TYPE_FLOAT, QUESTION_TYPE_DATE } from '../constants';

import { SurveyFormQuestionIconLabel } from '../survey_form_question_icon_label';

import './survey_new_attribute_modal.module.css';

export function SurveyNewAttributeModal({
  attribute,
  questionType,
  visible,
  onChange,
  onClose,
  onSave,
}) {
  const attributeTypes = {
    Text: 'string',
    [QUESTION_TYPE_BOOLEAN]: 'boolean',
    [QUESTION_TYPE_DATE]: 'date',
    [QUESTION_TYPE_FLOAT]: 'decimal',
  }

  const defaultAttributeType = attributeTypes[questionType] || 'string';

  const dataTypeOptions = Object.entries(attributeTypes).map(([key, value]) => ({
    label: <SurveyFormQuestionIconLabel attribute={{ name: key, type: value }} />,
    value,
  }));

  const handleInputChange = ({ target: { name, value } }) => {
    const updatedAttribute = { ...attribute, [name]: value };
    onChange(updatedAttribute);
  };

  useEffect(() => {
    if (!attribute.type) {
      const updatedAttribute = { ...attribute, type: defaultAttributeType };
      onChange(updatedAttribute);
    }
  }, [attribute, defaultAttributeType, onChange]);

  return (
    <Modal
      className="NewAttributeModal"
      isOpen={visible}
      shouldCloseOnOverlayClick={false}
      size={MODAL_SIZES.MEDIUM}
      onRequestClose={onClose}
    >
      <ModalHeader
        title="Create a new attribute"
        titleId="NewAttributeModal"
        onRequestClose={onClose}
      />

      <ModalBody>
        <div className="ModalBody__description">
          {`Creating a new attribute allows you to collect additional data for\
 your Hub population. You'll be able to save a question to this attribute for use\
 in your opt-in form, as well as map data to it from future CSV uploads.`}
        </div>

        <FormGroup
          label="Attribute name"
          labelHtmlFor="new-attribute-name"
        >
          <input
            className="form-control"
            id="new-attribute-name"
            name="name"
            type="text"
            value={attribute.name}
            onChange={handleInputChange}
          />
        </FormGroup>

        <FormGroup
          label="Attribute type"
          labelHtmlFor="new-attribute-type-select"
        >
          <SingleSelect
            defaultValue={dataTypeOptions.find(option => option.value === defaultAttributeType)}
            disabled
            id="new-attribute-type"
            inputId="new-attribute-type-select"
            name="type"
          />
          <div className="form-group__instructional-text">
            Attribute type is determined by the question type that you have selected.
          </div>
        </FormGroup>
      </ModalBody>

      <ModalFooter onRequestClose={onClose}>
        <button
          className="btn btn-primary"
          type="button"
          onClick={onSave}
        >
          Save attribute
        </button>
      </ModalFooter>
    </Modal>
  );
}

SurveyNewAttributeModal.propTypes = {
  attribute: PropTypes.object.isRequired,
  questionType: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};
