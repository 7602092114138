import React, { Component } from 'react';
import * as propTypes from 'lib/prop_types';

import { ErrorRenderer } from 'lib/errors';
import Http, { FormatResponse } from 'lib/http';
import * as routes from 'lib/routes';
import { IconButton, LoadingOverlay } from '@user-interviews/ui-design-system';

import StripeCardSelector from './stripe_card_selector';

import './stripe_card_manager.scss';

export default class StripeCardManager extends Component {
  static propTypes = {
    accountEmail: propTypes.string.isRequired,
    accountId: propTypes.oneOfType([propTypes.number, propTypes.string]).isRequired,
    editable: propTypes.bool,
    initialStripeCardId: propTypes.string,
    onSave: propTypes.func,
  };

  static defaultProps = {
    editable: false,
    initialStripeCardId: undefined,
    onSave: undefined,
  };

  state = {
    editMode: false,
    loading: true,
    stripeCardId: this.props.initialStripeCardId,
    stripeCards: [],
  };

  handleCancelClicked = () => {
    this.setState({
      editMode: false,
      stripeCardId: this.props.initialStripeCardId,
    });
  };

  handleCardChanged = (stripeCardId) => {
    this.setState({ stripeCardId });
  };

  handleCardsChanged = (stripeCards) => {
    this.setState({ stripeCards });
  };

  handleEditModeClicked = () => {
    this.setState({ editMode: true });
  };

  handleSaveClicked = () => {
    if (this.state.stripeCardId &&
      window.confirm('Are you sure you want to update the card on file?')
    ) {
      this.props.onSave(
        this.state.stripeCardId,
        () => this.setState({ editMode: false }),
      );
    }
  };

  handleToggleLoading = (loading) => {
    this.setState({ loading });
  };

  get selectedCard() {
    return this.state.stripeCards.find(card => card.id === this.state.stripeCardId);
  }

  async loadStripeCards() {
    this.setState({ loading: true });

    try {
      const response = await Http.get(
        routes.api_account_stripe_cards_path(this.props.accountId),
      );

      const cards = FormatResponse.jsonApi({})(response);

      this.setState({ stripeCards: cards });
    } catch (err) {
      ErrorRenderer.alert(err, 'fetching stripe cards');
    } finally {
      this.setState({ loading: false });
    }
  }

  componentDidMount() {
    this.loadStripeCards();
  }

  renderEditMode() {
    return (
      <>
        <StripeCardSelector
          accountEmail={this.props.accountEmail}
          accountId={this.props.accountId}
          className="form-control"
          inputId="stripe-card-select"
          stripeCardId={this.state.stripeCardId}
          stripeCards={this.state.stripeCards}
          onStripeCardChanged={this.handleCardChanged}
          onStripeCardsChanged={this.handleCardsChanged}
          onToggleLoading={this.handleToggleLoading}
        />
        <button
          aria-label="Save"
          className="btn btn-link btn-link--neutral"
          type="button"
          onClick={this.handleSaveClicked}
        >
          <i className="far fa-floppy-disk" />
        </button>
        <button
          aria-label="Cancel"
          className="btn btn-link btn-link--neutral"
          type="button"
          onClick={this.handleCancelClicked}
        >
          <i className="fas fa-xmark" />
        </button>
      </>
    );
  }

  renderViewMode() {
    return (
      <>
        <span className="form-control form-control-placeholder">
          {
            this.selectedCard ?
              `${this.selectedCard.brand} ending in ${this.selectedCard.lastFour}` :
              '\u00A0' // Put in an empty character to get some height
          }
        </span>
        {
          this.props.editable && (
            <IconButton
              action="EDIT"
              ariaLabel="Edit"
              onClick={this.handleEditModeClicked}
            />
          )
        }
      </>
    );
  }

  render() {
    return (
      <div className="stripe-card-manager">
        <LoadingOverlay visible={this.state.loading} />
        {
          this.state.editMode ?
            this.renderEditMode() :
            this.renderViewMode()
        }
      </div>
    );
  }
}
