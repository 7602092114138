import React, {
  useCallback,
  useContext,
  useMemo,
} from 'react';
import propTypes from 'prop-types';

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useDroppable,
  useSensor,
  useSensors,
} from '@dnd-kit/core';

import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import { restrictToVerticalAxis } from '@dnd-kit/modifiers';

import { isOtherAnswer } from 'researcher/surveys/utils';

import SurveyContext from '../context';

import { Answer } from './answer';

import './question.scss';

export function Answers({
  isEditing,
  question,
}) {
  const { answerFunctions: { move: moveAnswer }, survey } = useContext(SurveyContext);

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {}),
  );

  const { setNodeRef } = useDroppable({ id: 'answerList' });

  const questionUUID = question.uuid;

  const handleDragEnd = useCallback((event) => {
    const activeAccessor = event?.active?.id;
    const overAccessor = event?.over?.id;

    if (overAccessor && (activeAccessor !== overAccessor)) {
      moveAnswer(questionUUID, activeAccessor, overAccessor);
    }
  }, [moveAnswer, questionUUID]);

  const surveyAnswers = survey.answers;

  const answers = useMemo(
    () => question.answerIds?.filter(id => !!surveyAnswers[id]).map((answerId) => surveyAnswers[answerId]),
    [question, surveyAnswers],
  );

  const isRemovableAnswer = useMemo(
    () => answers.filter(answer => !isOtherAnswer(answer)).length > 1,
    [answers],
  );

  const answerUUIDs = answers.map(answer => answer.uuid);

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
      sensors={sensors}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={answerUUIDs} strategy={verticalListSortingStrategy}>
        <div
          id="answerList"
          ref={setNodeRef}
        >
          {answers.map((answer, index) => (
            <Answer
              answer={answer}
              index={index}
              isEditing={isEditing}
              isRemovableAnswer={isRemovableAnswer}
              key={answer.uuid}
              questionId={question.uuid}
              type={question.pick}
            />
          ))}
        </div>
      </SortableContext>
    </DndContext>
  );
}

Answers.propTypes = {
  isEditing: propTypes.bool.isRequired,
  question: propTypes.shape({
    answerIds: propTypes.array.isRequired,
    errors: propTypes.shape({
      text: propTypes.string,
    }).isRequired,
    isEditing: propTypes.bool,
    isKey: propTypes.bool.isRequired,
    isMandatory: propTypes.bool.isRequired,
    isNew: propTypes.bool,
    pick: propTypes.string.isRequired,
    text: propTypes.string.isRequired,
    uuid: propTypes.string.isRequired,
  }).isRequired,
};
