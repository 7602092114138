import React, { PureComponent } from 'react';

import FullCalendar from '@fullcalendar/react';
import momentPlugin from '@fullcalendar/moment';
import interactionPlugin from '@fullcalendar/interaction';
import { LoadingOverlay, MessageTypes } from '@user-interviews/ui-design-system';
import classNames from 'classnames';
import {
  add,
  endOfMonth,
  endOfWeek,
  isBefore,
  format,
  parse,
  setHours,
  setMinutes,
  startOfDay,
} from 'date-fns';
import $ from 'jquery';
import moment from 'moment';
import pluralize from 'pluralize';

import { AuthUserContext } from 'common/authorization';
import { COMPENSATION_CANCELLATION_FEE } from 'lib/generated_constants/billing';
import { windowIsMobile } from 'lib/bootstrap';
import generateUUID from 'lib/generate_uuid';
import Http from 'lib/http';
import { closest } from 'lib/layout';
import { studyLengthPropType } from 'lib/prop_types/projects/details';
import { uiModClassName } from 'lib/classnames/ui_mod';
import { dayGridPlugin, timeGridPlugin } from 'lib/full_calendar';
import * as propTypes from 'lib/prop_types';

import EditEventModal from './edit_event_modal';

import { workingHoursPropType, eventPropType, savedCalendarStatePropType } from './prop_types';

import './project_calendar.scss';

const CALENDAR_SCROLL_HOUR = 8;
const COLUMN_HEADER_FORMAT = 'ddd, MMM D';

const FullCalendarViews = Object.freeze({
  FOUR_DAY: 'agendaFourDay',
  MONTH: 'dayGridMonth',
  ONE_DAY: 'day',
  WEEK: 'timeGridWeek',
});

const calRowSelector = 'td.fc-timegrid-slot.fc-timegrid-slot-lane';

function eventCheckSum(event) {
  return `${event.uuid}${event.start.format()}${event.end.format()}${event.numSlots}`;
}

function eventsCheckSum(events) {
  return events
    .map(eventCheckSum)
    .join(';');
}

function inPast(date) {
  const startOfToday = startOfDay(new Date());
  const compareDate = startOfDay(date);

  return isBefore(compareDate, startOfToday);
}

export default class ProjectCalendar extends PureComponent {
  static contextType = AuthUserContext;

  static propTypes = {
    allowPastEvents: propTypes.bool,
    defaultNumSlots: propTypes.oneOfType([propTypes.number, propTypes.string]),
    eventLength: studyLengthPropType.isRequired,
    events: propTypes.arrayOf(eventPropType).isRequired,
    eventStartBuffer: propTypes.number,
    externalEventURLs: propTypes.arrayOf(propTypes.string),
    height: propTypes.number.isRequired,
    isLoading: propTypes.bool,
    isMultiDay: propTypes.bool.isRequired,
    isOneOnOne: propTypes.bool.isRequired,
    isPrivateProject: propTypes.bool.isRequired,
    isReadonly: propTypes.bool,
    overlayText: propTypes.string,
    savedCalendarState: savedCalendarStatePropType,
    setToastMessage: propTypes.func.isRequired,
    showControls: propTypes.bool,
    showTimezone: propTypes.bool,
    timezone: propTypes.string,
    workingHours: workingHoursPropType,

    onAddEvent: propTypes.func.isRequired,
    onDismissUpdateEventModal: propTypes.func,
    onRemoveEvent: propTypes.func.isRequired,
    onSaveCalendarState: propTypes.func,
    onUpdateEvent: propTypes.func.isRequired,
  };

  static defaultProps = {
    allowPastEvents: false,
    defaultNumSlots: undefined,
    eventStartBuffer: 0,
    externalEventURLs: [],
    isLoading: false,
    isReadonly: false,
    onDismissUpdateEventModal: undefined,
    onSaveCalendarState: null,
    overlayText: '',
    savedCalendarState: {},
    showControls: false,
    showTimezone: true,
    timezone: null,
    workingHours: [],
  };

  state = {
    editEventId: null,
    eventSources: this.buildEventSources(),
    isLoading: true,
  };

  calendarComponent = React.createRef();

  windowIsMobile = windowIsMobile(false);

  windowIsTablet = windowIsMobile();

  handleDayClick = ({ date }) => {
    if (this.props.isReadonly) return null;
    if (!this.isValidStart(date)) return false;

    this.props.onAddEvent(this.buildEvent(date));

    return true;
  };

  handleEditModalClose = () => {
    this.setState({ editEventId: null });

    if (this.props.onDismissUpdateEventModal) {
      this.props.onDismissUpdateEventModal(this.editEvent);
    }
  };

  handleEventAllow = ({ start }) => (
    this.isValidStart(start)
  );

  handleEventClick = ({
    event, jsEvent,
  }) => {
    const {
      isProjectEvent,
      numScheduled,
      uuid,
    } = event.extendedProps;

    if (this.props.isReadonly || !isProjectEvent) {
      return;
    }

    const isClose = !!closest(jsEvent.target, '.close-button');
    const isEdit = !!closest(jsEvent.target, '.edit-button');

    if (isClose) {
      const shouldRemove = numScheduled === 0 || window.confirm(this.confirmRemoveText);

      if (shouldRemove) {
        this.props.onRemoveEvent(uuid);
      }
    } else if (isEdit) {
      this.setState({ editEventId: uuid });
    }
  };

  handleEventDrop = (eventDropInfo) => {
    if (!eventDropInfo.event) return;

    const { event } = eventDropInfo;

    this.props.onUpdateEvent(this.buildEvent(event.start, event.extendedProps));
  };

  handleEventRender = (arg) => {
    const { event } = arg;
    const element = $(arg.el);

    const {
      accountId,
      isProjectEvent,
      numScheduled,
    } = arg.event.extendedProps;

    const eventControls = (!this.props.isOneOnOne) ?
      `<div class='project-calendar__event__controls'>
        <i class='close-button fas fa-xmark'></i>
        <i class='edit-button fas fa-pencil'></i>
      </div>` :
      `<div class='project-calendar__event__controls'>
        <i class='close-button fas fa-xmark'></i>
      </div>`;

    if (isProjectEvent) {
      if (numScheduled > 0) {
        element.addClass(`project-calendar__event--scheduled`);
      }

      if (!this.props.isReadonly && (this.isValidStart(event.start) || numScheduled === 0)) {
        element.find('.fc-event-time, .fc-event-title').eq(0).prepend(eventControls);
      }
    } else {
      element.addClass(`project-calendar__event--synced ${uiModClassName(accountId)}`);
    }
  };

  handleFullcalendarLoadEvents = (fetchInfo, callback) => {
    callback(this.events.map(
      (event) => {
        const { numScheduled, numSlots } = event;
        const slotText = numScheduled > 0 ?
          `${numScheduled} / ${numSlots} slots scheduled` :
          `${numSlots} ${pluralize('slot', numSlots)}`;
        const title = this.props.isOneOnOne ? '' : slotText;

        return {
          ...event,
          start: event.start.toDate(),
          startEditable: this.props.isReadonly ? false : event.numScheduled === 0,
          // Multiday rendering needs an extra day
          end: ((this.props.isMultiDay || event.end.isBefore(event.start)) ?
            event.end.clone().add(1, 'day').toDate() : event.end.toDate()
          ),
          title,
          extendedProps: {
            numScheduled: event.numScheduled,
            numSlots: event.numSlots,
            isProjectEvent: true,
            uuid: event.uuid,
          },
        };
      },
    ));
  };

  handleEditModalUpdate = (updates) => {
    this.setState({ editEventId: null });

    this.props.onUpdateEvent({ ...this.editEvent, ...updates });
  };

  handleLoading = (isLoading) => {
    if (!isLoading) {
      this.setState({ isLoading });
    }
  };

  handleViewRender = (initialView) => {
    if (initialView === FullCalendarViews.WEEK) {
      this.viewRenderForAgendaWeek();
    } else if (initialView === FullCalendarViews.MONTH) {
      this.viewRenderForMonth(this);
    }
  };

  get confirmRemoveText() {
    const feeText = this.props.isPrivateProject ?
      `` :
      `\n\nNote: If a participant is unable to reschedule, you will be ` +
      `charged ${COMPENSATION_CANCELLATION_FEE * 100}% of the expected ` +
      `compensation for that participant.`;

    return (
      `Wait! Someone has been scheduled for this time slot. Are you sure you ` +
      `want to cancel this time slot? If so, the participant(s) will be ` +
      `automatically notified and given the chance to sign up for an ` +
      `alternative slot.${feeText}`
    );
  }

  get defaultNumSlots() {
    return parseInt(this.props.defaultNumSlots) || 1;
  }

  get editEvent() {
    return this.state.editEventId ?
      this.events.find(event => event.uuid === this.state.editEventId) :
      null;
  }

  get eventDuration() {
    const eventLength = { ...this.eventLength };

    if (this.props.isMultiDay) {
      let days = 0;
      if (eventLength.weeks) {
        days += eventLength.weeks * 7;
      }

      if (eventLength.days) {
        days += eventLength.days;
      }

      // Always make sure we have at least one day
      if (days === 0) {
        days = 1;
      }

      return { days };
    }

    if (eventLength.hours || eventLength.minutes) {
      return `${eventLength.hours || 0}:${eventLength.minutes || 0}:00`;
    }

    return '00:30:00';
  }

  get eventLength() {
    return this.props.eventLength;
  }

  get events() {
    return this.props.events;
  }

  get showControls() {
    return this.props.showControls;
  }

  get scrollTime() {
    let date = new Date();
    date = setHours(date, CALENDAR_SCROLL_HOUR);
    date = setMinutes(date, 0);
    const formattedTime = format(date, 'hh:mm');

    return formattedTime;
  }

  get timezone() {
    return this.props.timezone;
  }

  addEventSource(url) {
    this.setState(state => ({
      isLoading: true,
      eventSources: [...state.eventSources, this.buildEventSourceForURL(url)],
    }));
  }

  removeEventSource(url) {
    this.setState(state => ({
      eventSources: state.eventSources.filter(source => source.id !== url),
    }));
  }

  buildEventSources() {
    return this.props.externalEventURLs.map(url => this.buildEventSourceForURL(url));
  }

  buildCalendarOptions() {
    const eventSources = [{
      id: 'default',
      events: this.handleFullcalendarLoadEvents,
    }, ...this.state.eventSources];

    const calendarOptions = {
      allDaySlot: false,
      defaultAllDay: this.props.isMultiDay,
      customButtons: {},
      dateClick: this.handleDayClick,
      initialView: this.determineDefaultView(),
      eventAllow: this.handleEventAllow,
      eventClick: this.handleEventClick,
      eventDrop: this.handleEventDrop,
      eventDidMount: this.handleEventRender,
      eventSources,
      businessHours: this.props.workingHours,
      eventStartEditable: true,
      forceEventDuration: true,
      handleWindowResize: true,
      headerToolbar: {
        left: 'today prev,next',
        center: '',
        right: '',
      },
      height: this.props.height,
      plugins: [dayGridPlugin, interactionPlugin, momentPlugin, timeGridPlugin],
      slotDuration: '00:15:00',
      timeZone: this.timezone,
      datesSet: this.handleViewRender.bind(this),
      loading: this.handleLoading,
      eventClassNames: 'project-calendar__event',
    };

    if (this.props.isMultiDay) {
      calendarOptions.defaultAllDayEventDuration = this.eventDuration;

      // If we're not showing any valid dates, go to the next month
      const endOfThisMonth = endOfMonth(new Date());
      if (!this.isValidStart(endOfThisMonth)) {
        calendarOptions.initialDate = add(endOfThisMonth, { days: 1 });
      }

      if (this.showControls) {
        calendarOptions.titleFormat = 'MMM YYYY';
        calendarOptions.headerToolbar = { left: 'title prev,next', center: '', right: '' };
      } else {
        calendarOptions.headerToolbar = { left: 'title', center: '', right: 'today prev,next' };
      }
    } else {
      const isAllDaySlot = false;
      const type = 'timeGrid';

      calendarOptions.defaultTimedEventDuration = this.eventDuration;
      calendarOptions.views = {
        [FullCalendarViews.FOUR_DAY]: {
          allDaySlot: isAllDaySlot,
          dayHeaderFormat: COLUMN_HEADER_FORMAT,
          duration: { days: 4 },
          scrollTime: this.scrollTime,
          type,
        },
        [FullCalendarViews.ONE_DAY]: {
          allDaySlot: isAllDaySlot,
          dayHeaderFormat: COLUMN_HEADER_FORMAT,
          duration: { days: 1 },
          scrollTime: this.scrollTime,
          type,
        },
        [FullCalendarViews.WEEK]: {
          dayHeaderFormat: COLUMN_HEADER_FORMAT,
          scrollTime: this.scrollTime,
        },
      };

      // If we're not showing any valid dates, go to the next week
      const endOfThisWeek = endOfWeek(new Date());
      if (!this.isValidStart(endOfThisWeek)) {
        calendarOptions.initialDate = add(endOfThisWeek, { days: 1 });
      }

      calendarOptions.headerToolbar = { left: 'today prev,next', center: '', right: '' };
    }

    if (this.props.savedCalendarState) {
      Object.keys(this.props.savedCalendarState).forEach(
        (key) => { calendarOptions[key] = this.props.savedCalendarState[key]; },
      );
    }

    return calendarOptions;
  }

  buildEvent(date, templateEvent) {
    const start = moment.utc(date);
    const end = start.clone();

    if (this.props.isMultiDay) {
      end.add(this.eventDuration.days - 1, 'days');
    } else {
      end.add(moment.duration(this.eventDuration));
    }

    if (!templateEvent) {
      // Disable linter so we can use this as a default param
      // eslint-disable-next-line no-param-reassign
      templateEvent = {
        numScheduled: 0,
        numSlots: this.defaultNumSlots,
        uuid: generateUUID(),
      };
    }

    return {
      ...templateEvent,
      allDay: this.props.isMultiDay,
      end,
      errors: {},
      start,
    };
  }

  buildEventSourceForURL(url) {
    return {
      id: url,
      editable: false,
      startEditable: false,
      durationEditable: false,
      className: 'project-calendar__event',
      events: async ({ startStr, endStr }, successCallback, failureCallback) => {
        const data = {
          start: startStr,
          end: endStr,
          timezone: this.timezone,
        };

        try {
          const response = await Http.get(url, data);

          const projectEventIds = this.events
            .filter(event => event.calendarEventId)
            .map(event => event.calendarEventId);

          const filteredEvents = response
            .filter(event => !projectEventIds.includes(event.id))
            // a workaround for a FC bug that causes nothing to render
            // if any event has the same start and end time
            .filter(event => event.start !== event.end)
            .map(event => ({
              ...event,
              title: event.accountFirstName,
              extendedProps: {
                accountId: event.accountId,
              },
            }));

          this.setState({ isLoading: false });
          successCallback(filteredEvents);
        } catch (err) {
          // we don't want to show this toast unless we know the error is related
          // to the user's credentials expiring
          if (err?.content?.message) {
            this.props.setToastMessage({
              type: MessageTypes.ERROR,
              message: err.content.message,
            });
          }
          this.setState({ isLoading: false });
          failureCallback(err);
        }
      },
    };
  }

  determineDefaultView() {
    if (this.props.isMultiDay) return FullCalendarViews.MONTH;
    if (this.windowIsMobile) return FullCalendarViews.ONE_DAY;
    if (this.windowIsTablet) return FullCalendarViews.FOUR_DAY;

    return FullCalendarViews.WEEK;
  }

  isValidStart(date) {
    if (this.props.allowPastEvents) { return true; }

    const compareDate = new Date(date);
    const startBufferDate = add(compareDate, { days: this.props.eventStartBuffer });
    const isBeforeStartBuffer = isBefore(compareDate, startBufferDate);

    return !isBeforeStartBuffer && !inPast(compareDate);
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  requestResize() {
    // This should be called when the space the calendar should fill is adjusted
    // without a browser resize (e.g. adding / removing surrounding elements).
    // This will trigger a resize which will adjust the height of the calendar
    // based on the given height function or parameter.
    window.dispatchEvent(new Event('resize'));
  }

  unbindCellHoverListeners() {
    if (this.cellsWithEventListeners) {
      this.cellsWithEventListeners.forEach(element => $(element).off('mouseenter'));
      this.cellsWithEventListeners = [];
    }
    $(document).off('mouseenter', calRowSelector);
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  onCellHoverListenerAdded(element) {
    this.cellsWithEventListeners = this.cellsWithEventListeners || [];
    this.cellsWithEventListeners.push(element);
  }

  viewRenderForAgendaWeek() {
    if (this.props.isReadonly) return;
    // Go through each row and add a hover event to add the dynamic background
    // It may seem better to just do this on initial render but it really slows the page down
    // Better to do it dynamically when a row is first hovered over -BHS

    // This is some bananas shit we have to do because FullCalendar does not provide a function
    // to add hover styling for timeslots- so we basically create a mouseenter event that adds
    // custom column cells on top of the timeslot row and a hover event that adds
    // a background color on hover. We remove it once the mouse leaves - AMA
    // https://github.com/fullcalendar/fullcalendar/issues/4816
    const component = this;
    $(document).on({
      mouseenter() {
        const cellWidth = $('th.fc-col-header-cell').width();
        const cellHeight = $(this).height();
        const columnCount = $('table.fc-col-header th.fc-col-header-cell').children().length;

        if (!$(this).html()) {
          for (let i = 0; i < columnCount; i++) {
            $(this).append(
              `<td
                class="temp-cell"
                style="border:0px; height:${cellHeight - 1}px;width:${cellWidth + 3}px">
              </td>`,
            );
          }
        }
        $(this).children('td').each(function () {
          component.onCellHoverListenerAdded(this);
          $(this).hover(function () {
            const parsedTime = parse($(this).parent().data('time'), 'HH:mm:ss', new Date());
            $(this).html(`<div class="hover-time">${format(parsedTime, 'h:mmaaa')}</div>`);
          }, function () {
            $(this).html('');
          });
        });
      },

      mouseleave() {
        $(this).children('.temp-cell').remove();
      },

    }, calRowSelector);
  }

  viewRenderForMonth(contextThis) {
    if (this.props.isReadonly) return;

    document.querySelectorAll('.fc-day-future').forEach((currentNode) => {
      const cellSelector = $(currentNode);

      if (contextThis.isValidStart(cellSelector.data('date'))) {
        cellSelector.addClass('selectable');
      }
    });
  }

  componentDidUpdate(prevProps) {
    const currentCollaboratorUrls = new Set(this.props.externalEventURLs);
    const prevCollaboratorUrls = new Set(prevProps.externalEventURLs);
    const calendarComponent = this.calendarComponent.current;
    const { initialView } = calendarComponent.props;

    const addedCollaboratorUrls =
      [...currentCollaboratorUrls].filter(url => !prevCollaboratorUrls.has(url));
    const removedCollaboratorUrls =
      [...prevCollaboratorUrls].filter(url => !currentCollaboratorUrls.has(url));

    if (prevProps.timezone !== this.props.timezone) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isLoading: true }, () => calendarComponent.getApi().refetchEvents());
    } else if (eventsCheckSum(prevProps.events) !== eventsCheckSum(this.props.events)) {
      calendarComponent.getApi().getEventSourceById('default').refetch();
    }

    if (addedCollaboratorUrls.length > 0) {
      addedCollaboratorUrls.forEach(url => this.addEventSource(url));
    }

    if (removedCollaboratorUrls.length > 0) {
      removedCollaboratorUrls.forEach(url => this.removeEventSource(url));
    }

    // Changing the view (month/week) programmatically here because FullCalendar does not
    // re-render it when the initial view changes. This allows the workspace
    // to change activity types without refreshing the page
    if (prevProps.isMultiDay !== this.props.isMultiDay) {
      calendarComponent.getApi().changeView(this.determineDefaultView())
    }

    this.handleViewRender(initialView);

    if (this.showControls && this.props.timezone && this.props.showTimezone) {
      const element =
        document.querySelector('.fc-toolbar-ltr .fc-toolbar-chunk').nextElementSibling;
      element.innerHTML = `<div class="fc-center__timezone-info">
          Timezone: <b>${this.props.timezone}</b>
        </div>`;
    }

    if (this.props.isReadonly && !prevProps.isReadonly) {
      this.unbindCellHoverListeners();
    }
  }

  componentWillUnmount() {
    if (this.props.onSaveCalendarState) {
      const { current } = this.calendarComponent;
      if (current) {
        this.props.onSaveCalendarState({
          initialDate: current.getApi().getDate(),
        });
      }
    }
  }

  render() {
    const { editEvent } = this;

    return (
      <>
        <LoadingOverlay
          text={this.props.overlayText}
          visible={this.state.isLoading || this.props.isLoading}
        />
        <div
          className={classNames(
            'project-calendar',
            { 'project-calendar__working-hours-view': !!this.props.workingHours.length },
          )}
          data-testid="project-calendar"
          id="project-calendar"
        >
          <FullCalendar
            ref={this.calendarComponent}
            {...this.buildCalendarOptions()}
          />
          {
            (!this.props.isOneOnOne) && (
              <EditEventModal
                isOneOnOne={this.props.isOneOnOne}
                isOpen={!!editEvent}
                {...editEvent}
                onRequestClose={this.handleEditModalClose}
                onUpdate={this.handleEditModalUpdate}
              />
            )
          }
        </div>
      </>
    );
  }
}
