import React, { useContext } from 'react';
import propTypes from 'prop-types';

import SurveyContext from 'common/components/survey_builder/context';

import { Text } from '@user-interviews/ui-design-system';

import * as Constants from '../../constants';

import { Pick } from './pick';
import { QualifyLogicSelect } from './qualify_logic_select';

export function OtherAnswer({
  answer,
  isEditing,
  questionType,
  onChange,
}) {
  const { formType } = useContext(SurveyContext);

  const isPickOne = questionType === Constants.QUESTION_PICK_ONE;

  if (!isEditing) {
    return (
      <Pick
        answer={answer}
        isEditing={isEditing}
        questionType={questionType}
      />
    );
  }

  return (
    <>
      <div
        className="SurveyBuilderRow editing other"
        style={isEditing ? {} : { display: 'none' }}
      >
        <div className="SurveyBuilderRow__text">
          <div className="SurveyBuilderRow__text__answer">
            <div className="drag-handle-wrapper" />
            <div className="checkbox-wrapper">
              <input
                aria-label="other answer selected preview"
                disabled
                type={isPickOne ? 'radio' : 'checkbox'}
              />
            </div>
            <div className="answer-wrapper">
              <Text>Other</Text>
            </div>
          </div>
        </div>
        <div className="SurveyBuilderRow__controls" />
        <div className="SurveyBuilderRow__actions" />
      </div>
      <div
        className="SurveyBuilderRow editing other second-line"
        style={isEditing ? {} : { display: 'none' }}
      >
        <div className="SurveyBuilderRow__text">
          <div className="SurveyBuilderRow__text__answer">
            <div className="drag-handle-wrapper" />
            <div className="checkbox-wrapper" />
            <div className="answer-wrapper">
              <textarea
                aria-label="option for other input"
                className="form-control"
                disabled
              />
            </div>
          </div>
        </div>
        {
          formType === Constants.SCREENER_FORM_TYPE && (
            <div className="SurveyBuilderRow__controls">
              <QualifyLogicSelect
                className="SurveyBuilderQuestion__type answer"
                isPickOne={isPickOne}
                value={answer.qualifyLogic}
                onAnswerChange={onChange}
              />
            </div>
          )
        }
        <div className="SurveyBuilderRow__actions" />
      </div>
    </>
  );
}

OtherAnswer.propTypes = {
  answer: propTypes.shape({
    errors: propTypes.object.isRequired,
    qualifyLogic: propTypes.string.isRequired,
    responseClass: propTypes.string,
    text: propTypes.string,
    uuid: propTypes.string.isRequired,
  }).isRequired,
  isEditing: propTypes.bool.isRequired,
  questionType: propTypes.string.isRequired,
  onChange: propTypes.func.isRequired,
};
