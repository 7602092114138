import React, { useMemo } from 'react';
import propTypes from 'prop-types';

import {
  sortAscendingRegex,
  sortDescendingRegex,
} from 'lib/formatters/query_string';

import * as constants from '../../../lib/constants/sort_data_actions_constants';

import { SortControl } from './sort_control';

import './sort_data_actions.scss';

export function SortActions({
  hideActionsAfterSortChanged,
  sortableType,
  sortParamKey,
  sortParamString,
  trackingEventName,
  onSortChanged,
}) {
  const currentSortDirection = useMemo(() => {
    let direction;

    if (sortParamString?.match(sortAscendingRegex(sortParamKey))) {
      direction = constants.SORT_DIRECTION_ASCENDING;
    }

    if (sortParamString?.match(sortDescendingRegex(sortParamKey))) {
      direction = constants.SORT_DIRECTION_DESCENDING;
    }

    return direction;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortParamString]);

  const handleSortControlClick = (direction) => {
    onSortChanged(sortParamKey, direction);
    hideActionsAfterSortChanged();
  };

  const sortAscendingActive = currentSortDirection === constants.SORT_DIRECTION_ASCENDING;
  const sortDescendingActive = currentSortDirection === constants.SORT_DIRECTION_DESCENDING;

  return (
    <>
      <SortControl
        active={sortAscendingActive}
        sortDirection={constants.SORT_DIRECTION_ASCENDING}
        text={constants.SORT_CONTENT_MAP[sortableType].text[constants.SORT_DIRECTION_ASCENDING]}
        trackingEvent={`${trackingEventName} - Sort ${sortParamKey} clicked`}
        trackingEventData={{
          fieldName: sortParamString,
          sortDirection: sortAscendingActive ?
            constants.SORT_DIRECTION_RESET :
            constants.SORT_DIRECTION_ASCENDING,
        }}
        onControlClick={handleSortControlClick}
      />
      <br />
      <SortControl
        active={sortDescendingActive}
        sortDirection={constants.SORT_DIRECTION_DESCENDING}
        text={constants.SORT_CONTENT_MAP[sortableType].text[constants.SORT_DIRECTION_DESCENDING]}
        trackingEvent={`${trackingEventName} - Sort ${sortParamKey} clicked`}
        trackingEventData={{
          fieldName: sortParamString,
          sortDirection: sortDescendingActive ?
            constants.SORT_DIRECTION_RESET :
            constants.SORT_DIRECTION_DESCENDING,
        }}
        onControlClick={handleSortControlClick}
      />
    </>
  );
}

SortActions.propTypes = {
  hideActionsAfterSortChanged: propTypes.func.isRequired,
  sortableType: propTypes.oneOf([
    constants.SORT_TYPE_DATE,
    constants.SORT_TYPE_NUMERIC,
    constants.SORT_TYPE_STRING,
  ]).isRequired,
  sortParamKey: propTypes.string.isRequired,
  sortParamString: propTypes.string.isRequired,
  trackingEventName: propTypes.string,
  onSortChanged: propTypes.func.isRequired,
};

SortActions.defaultProps = {
  trackingEventName: '',
};
