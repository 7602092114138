import React from 'react';
import propTypes from 'prop-types';

import * as Constants from '../../constants';

export function PickNone({ answer }) {
  if (answer.responseClass === Constants.RESPONSE_CLASS_STRING) {
    return <input aria-label="short answer" className="form-control" disabled type="text" />;
  }

  if (answer.responseClass === Constants.RESPONSE_CLASS_TEXT) {
    return (
      <textarea
        aria-label="long answer"
        className="form-control"
        disabled
        style={{ resize: 'none' }}
      />
    );
  }

  if (answer.responseClass === Constants.RESPONSE_CLASS_DATE) {
    return <input aria-label="date" className="form-control" disabled type="date" />;
  }

  if (answer.responseClass === Constants.RESPONSE_CLASS_FLOAT) {
    return <input aria-label="number" className="form-control" disabled type="number" />;
  }

  return null;
}

PickNone.propTypes = {
  answer: propTypes.shape({
    responseClass: propTypes.oneOf([
      Constants.RESPONSE_CLASS_STRING,
      Constants.RESPONSE_CLASS_TEXT,
      Constants.RESPONSE_CLASS_DATE,
      Constants.RESPONSE_CLASS_FLOAT,
    ]).isRequired,
  }).isRequired,
};
