import React, { PureComponent } from 'react';
import * as propTypes from 'lib/prop_types';

import { Button } from '@user-interviews/ui-design-system';

import { ENTER } from 'lib/keycodes';

import { EditControl } from '../edit_control';

export class Edit extends PureComponent {
  static propTypes = {
    comment: propTypes.shape({
      body: propTypes.string.isRequired,
    }).isRequired,
    commenters: propTypes.object,
    inviteDomain: propTypes.string,
    onCancel: propTypes.func.isRequired,
    onInvite: propTypes.func,
    onSave: propTypes.func.isRequired,
  };

  static defaultProps = {
    commenters: {},
    inviteDomain: undefined,
    onInvite: undefined,
  };

  state = { body: this.props.comment.body };

  handleCommentInputChange = ({ target: { value } }) => {
    this.setState({ body: value });
  };

  handleCommentKeyDown = (event) => {
    if (event.keyCode !== ENTER || event.shiftKey) return;

    event.preventDefault();
    this.handleSave();
  };

  handleSave = () => {
    this.props.onSave(this.state.body);
  };

  render() {
    return (
      <div className="Comment__body Comment__body--editing">
        <EditControl
          commenters={this.props.commenters}
          inviteDomain={this.props.inviteDomain}
          value={this.state.body}
          onChange={this.handleCommentInputChange}
          onInvite={this.props.onInvite}
          onKeyDown={this.handleCommentKeyDown}
        />

        <div className="Comment__edit-controls">
          <Button variant="transparent" onClick={this.props.onCancel}>Cancel</Button>
          <Button variant="primary" onClick={this.handleSave}>Save</Button>
        </div>
      </div>
    );
  }
}
