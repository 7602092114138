import React, { useContext, useCallback } from 'react';
import propTypes from 'prop-types';
import { Button } from '@user-interviews/ui-design-system';
import { faPlusCircle } from 'lib/font_awesome/solid';

import SurveyContext from '../context';
import { surveyPropType } from '../prop_types';

import { SkipLogic } from './skip_logic';

export function SectionSkipLogic({
  isReadonly,
  section,
  survey,
}) {
  const { skipLogicFunctions } = useContext(SurveyContext);

  const {
    add: addSkipLogic,
    remove: removeSkipLogic,
    update: updateSkipLogic,
  } = skipLogicFunctions;

  const handleAdd = useCallback(
    () => addSkipLogic(section.uuid), [addSkipLogic, section.uuid],
  );

  if (!section.skipLogicIds?.length) {
    return (
      <p className="SkipLogicModal__empty-text">
        There is no skip logic for this section.
      </p>
    )
  }

  return (
    <>
      {section.skipLogicIds.map((skipLogicId) => {
        const skipLogic = survey.skipLogics[skipLogicId];

        return (
          <SkipLogic
            isReadonly={isReadonly}
            key={skipLogic.uuid}
            sectionId={section.uuid}
            skipLogic={skipLogic}
            survey={survey}
            onRemove={removeSkipLogic}
            onUpdate={updateSkipLogic}
          />
        );
      })}

      {!isReadonly && (
        <Button
          className="btn-link--no-padding"
          leadingIcon={faPlusCircle}
          variant="link"
          onClick={handleAdd}
        >
          Add new logic statement
        </Button>
      )}
    </>
  );
}

SectionSkipLogic.propTypes = {
  isReadonly: propTypes.bool.isRequired,
  section: propTypes.object.isRequired,
  survey: surveyPropType.isRequired,
};
