/* eslint-disable max-len */
import React from 'react';

export function Surveymonkey({ className = '' } : { className?: string }) {
  return (
    <svg
      className={`SurveyMonkey ${className}`}
      data-name="Layer 1"
      id="Layer_1"
      viewBox="0 0 222.61 155.27"
    >
      <path
        d="M249,141.26a26.52,26.52,0,0,0-6.29.78,81.08,81.08,0,0,0-64.19-59.81c-1.4-.25-2.66-.44-4.09-.62h0c.24-7.66.59-16.51,11.86-24.47l-1.78-4.49s-22,6.82-24.49,25.61c-1.09-5.11-11.33-11.51-16.4-12.72l-2.52,4.07s6.72,3.36,8.36,12.63h0A81.08,81.08,0,0,0,85.24,142a26.3,26.3,0,1,0,3.32,50,80.63,80.63,0,0,0,8.54,15.89l21.83-14.71-.19-.24c-5.77-7.42-9.3-18.34-9.9-29.21-.65-12,2.27-23.9,9.93-30.9,15.79-13.44,33-7.31,43.74,5.57h2.89c10.77-12.88,28-19,43.74-5.57,7.65,7,10.58,18.91,9.93,30.9-.59,10.87-4.12,21.79-9.9,29.21l-.19.24,21.83,14.71A80.63,80.63,0,0,0,239.37,192,26.29,26.29,0,1,0,249,141.26Zm-170.53,34a7.76,7.76,0,0,1,0-15.52,7.83,7.83,0,0,1,4.35,1.34,117.87,117.87,0,0,0,.83,12.19A7.76,7.76,0,0,1,78.44,175.31Zm171,0a7.76,7.76,0,0,1-5.18-2,117.87,117.87,0,0,0,.83-12.19,7.75,7.75,0,0,1,12.1,6.43A7.74,7.74,0,0,1,249.48,175.31Z"
        data-name="&lt;Compound Path&gt;"
        fill="#00bf6f"
        id="_Compound_Path_"
        transform="translate(-52.66 -52.66)"
      />
    </svg>
  )
}
