import React from 'react';

import './empty.scss';

export function Empty() {
  return (
    <div className="Comments__empty">
      There aren’t any comments at the moment
    </div>
  )
}
