import React, {
  useContext,
  useMemo,
  useState,
} from 'react';
import { useTracking } from 'react-tracking';
import {
  Alert, FormGroup, Input, MessageTypes, SingleSelect,
} from '@user-interviews/ui-design-system';

import { AuthUserContext } from 'common/authorization';
import { AccountCollaboratorsContext } from 'hoc/with_account_collaborators_context';

import { trackingEvents } from 'lib/analytics';
import {
  providerIcons,
  providerNames,
  providers,
  manualProviders,
  unmoderatedProviders,
} from 'lib/generated_constants/integrations';
import * as propTypes from 'lib/prop_types';

import ConnectModal from './connect_modal';
import ConnectProviderButton from './connect_provider_button';
import TaskSelect from './task_select';

import './project_task_control.scss';

// TODO: TDW-SUNSET

function ProviderOption({
  Icon,
  isConnectable,
  isConnected,
  name,
}) {
  return (
    <div aria-label={name} aria-selected={false} role="option">
      {isConnected && Icon &&
      (
        <Icon className="ProjectTaskControl__icon icon-left" />
      )}
      {isConnected || !isConnectable ? name : `Connect your ${name} account`}
    </div>
  )
}

const DEFAULT_OPTION = Object.freeze({ label: 'Select a tool...', value: '' });

function getHelperText(providerValue) {
  if (providerValue?.value === providerNames.NO_LINK) {
    return 'Please make sure you manually provide any task links to your participants.';
  }

  return '';
}

ProviderOption.propTypes = {
  Icon: propTypes.elementType,
  isConnectable: propTypes.bool.isRequired,
  isConnected: propTypes.bool.isRequired,
  name: propTypes.string.isRequired,
};

ProviderOption.defaultProps = {
  Icon: undefined,
};

function ProjectTaskControl({
  errors,
  isReadOnly,
  value: task,
  onChange,
  onConnectProvider,
}) {
  const { trackEvent } = useTracking();
  const { owner } = useContext(AccountCollaboratorsContext);
  const integrations = useMemo(() => owner?.integrations || [], [owner]);

  const { account, integrationAPIKeys } = useContext(AuthUserContext);
  const isAuthUser = account?.id && account?.id === owner?.id;
  const taskProvider = task?.provider;

  const [connectProvider, setConnectProvider] = useState();
  const [suggestedProvider, setSuggestedProvider] = useState();

  const handleModalClose = () => setConnectProvider();

  const providerOptions = useMemo(
    () => unmoderatedProviders.reduce(
      (acc, p) => {
        const { hasAPIKey, isConnectable } = providers[p];
        // TODO: Add a modal for adding API keys...if that is what we want. Currently these can
        // only be added on the integrations page.
        // For now we just don't show the option unless the user has access to an API key.
        if (hasAPIKey && !integrationAPIKeys.includes(p)) return acc;

        const isConnected = integrations.includes(p);
        // We need to filter out unconnected integrations if the owner is not the auth user since
        // they aren't able nor allowed to connect a new integration on behalf of another account.
        // This case is currently for staff or org admins.
        if (!isAuthUser && isConnectable && !isConnected) return acc;

        acc.push({
          ...providers[p],
          label: <ProviderOption {...{ ...providers[p], Icon: providerIcons[p], isConnected }} />,
        });
        return acc;
      },
      [],
    ).sort(
      (a, b) => {
        // alphabetical amongst connected integrations
        if (integrations.includes(a.value) && integrations.includes(b.value)) {
          return a.value - b.value;
        }

        if (integrations.includes(a.value) && !integrations.includes(b.value)) return -1;
        if (!integrations.includes(a.value) && integrations.includes(b.value)) return 1;
        // These are already sorted into integrations and non-integrations and then alphabetical in
        // the constants file so we to preserve that sort.
        return 0;
      },
    ),
    [integrationAPIKeys, integrations, isAuthUser],
  );

  const providerValue = useMemo(
    () => {
      const option = providerOptions.find(opt => opt.value === taskProvider);
      // An integration selected that isn't connected gets cleared out since that means they opened
      // the connect modal but closed it without connecting.
      if (!option || (option?.isConnectable && !integrations.includes(option?.value))) return null;

      return option;
    },
    [integrations, providerOptions, taskProvider],
  );

  const handleExternalTaskChanged = ({ externalId, title, url }) => onChange({
    ...task,
    externalId,
    title,
    url,
  });

  const handleProviderChanged = async (option) => {
    // When provider changes we need to clear out all other sub values bc they derive from provider.

    onChange({
      ...task,
      externalId: '',
      provider: option.value,
      title: '',
      url: '',
      connectedAt: null,
    });

    // If the chosen option is not an integration or it is already connected there is nothing more
    // to do here.
    if (!option.isConnectable || integrations.includes(option.value)) return;

    // If the chosen option is an integration and it is not connected we fire our callbacks to give
    // the parent the ability to respond and open the connect modal.
    if (onConnectProvider) await onConnectProvider(option.value);
    setConnectProvider(option);
  };

  const allProviders = Object.values(providerNames);
  const validIntegrations = allProviders.filter(name => providers[name]?.urlPattern);
  const integrationsRegexLookupTable = validIntegrations.reduce((acc, currentProvider) => {
    acc[providers[currentProvider].name] = providers[currentProvider].urlPattern;
    return acc;
  }, {});

  const suggestedProviderForUrl = (url) => {
    for (const [providerName, urlRegex] of Object.entries(integrationsRegexLookupTable)) {
      if (urlRegex.test(url)) {
        return providerName;
      }
    }

    return null;
  };

  const providerKeyFromName = provider => provider?.toLowerCase().replace(' ', '_');

  const connectSuggestedProviderInfoAlertText = (providerName) => {
    const isActiveIntegration = integrations.includes(providerKeyFromName(providerName));
    const continueButtonAriaLabel = isActiveIntegration ?
      `Your ${suggestedProvider} account is already connected - click to continue` :
      `Continue with connecting your ${suggestedProvider} account`;
    const message = isActiveIntegration ?
      'Use the integration with one click. It handles redirect links, automates progress ' +
      'tracking and participant communication.' :
      `We have an integration with ${suggestedProvider} that handles redirect` +
      `links and automates progress tracking and participant communication.`;
    const title = isActiveIntegration ?
      `Congratulations! Your ${suggestedProvider} account is already connected.` :
      `Connect your ${suggestedProvider} account with one click!`;

    return { continueButtonAriaLabel, message, title };
  };

  const handleUrlChanged = (
    { currentTarget: { value: url } },
  ) => {
    const provider = suggestedProviderForUrl(url);
    setSuggestedProvider(provider);
    if (provider) {
      trackEvent({
        event: trackingEvents.SUGGESTED_INTEGRATION_ALERT_SHOWN,
        eventData: { provider },
      });
    }
    onChange({
      ...task, url, connectedAt: null,
    });
  };

  const handleSuggestedProviderConnectClick = () => {
    trackEvent({
      event: trackingEvents.SUGGESTED_INTEGRATION_ALERT_CONNECT_CLICKED,
      eventData: {
        provider: suggestedProvider,
        message: connectSuggestedProviderInfoAlertText(suggestedProvider).title,
      },
    });
    handleProviderChanged({
      isConnectable: true,
      name: suggestedProvider,
      value: providers[providerKeyFromName(suggestedProvider)]?.value,
    });
    setSuggestedProvider(null);
  };

  const helperText = getHelperText(providerValue);
  const isManual = manualProviders.includes(providerValue?.value);

  return (
    <>
      <ConnectModal
        provider={connectProvider}
        onClose={handleModalClose}
      />

      <FormGroup
        className="ProjectTaskControl"
        errors={errors}
        helperText={helperText}
        inputKey="task"
        label="Link to task"
        labelHtmlFor="project-task-provider"
        labelTooltip={
          `Link to task will be included in the confirmation and reminder emails ` +
            `sent to confirmed participants.`
        }
      >
        <div className="ProjectTaskControl__inputs">
          <Input
            name="accountId"
            type="hidden"
            // eslint todo TODO Mike/Alex D
            value={task?.accountId || owner?.id} // eslint-disable-line react/prop-types
          />

          <SingleSelect
            className="ProjectTaskControl__provider"
            defaultValue={DEFAULT_OPTION}
            disabled={isReadOnly}
            inputId="project-task-provider"
            instanceId="provider"
            name="provider"
            options={providerOptions}
            placeholder="Select a tool..."
            value={providerValue}
            onChange={handleProviderChanged}
          />

          {isManual &&
            (
              <Input
                aria-label="Task url"
                disabled={isReadOnly}
                id="project-task-url"
                name="url"
                placeholder="Link must lead with http:// or https://"
                // eslint TODO Mike/Alex D
                value={task.url || ''} // eslint-disable-line react/prop-types
                onChange={handleUrlChanged}
              />
            )}

          {suggestedProvider && (
            <Alert
              action={(
                <ConnectProviderButton
                  ariaLabel={
                    connectSuggestedProviderInfoAlertText(suggestedProvider).continueButtonAriaLabel
                  }
                  onClick={() => handleSuggestedProviderConnectClick(suggestedProvider)}
                />
              )}
              aria-label="Connect an integration provider"
              className="ProjectTaskControl__connect-suggested-integration-alert"
              message={connectSuggestedProviderInfoAlertText(suggestedProvider).message}
              removeBorderLeft
              title={connectSuggestedProviderInfoAlertText(suggestedProvider).title}
              type={MessageTypes.INFO}
            />
          )}

          {!isManual && integrations.includes(providerValue?.value) &&
            (
              <TaskSelect
                accountId={owner.id}
                disabled={isReadOnly}
                provider={providerValue.value}
                value={task}
                onChange={handleExternalTaskChanged}
              />
            )}
        </div>
      </FormGroup>
    </>
  );
}

ProjectTaskControl.propTypes = {
  errors: propTypes.object,
  isReadOnly: propTypes.bool,
  value: propTypes.shape({
    provider: propTypes.string,
  }),
  onChange: propTypes.func,
  onConnectProvider: propTypes.func,
};

ProjectTaskControl.defaultProps = {
  errors: {},
  isReadOnly: false,
  value: {},
  onChange: undefined,
  onConnectProvider: undefined,
};

export default ProjectTaskControl;
