/* eslint-disable max-len */
import React from 'react';

/**
 * Font Awesome currently does not have an outlook icon so this should do the job.
 * This should work similarly to a Font Awesome icon (match font color / size).
 *
 * This will create an i element with the provided className (as Font Awesome does).
 */
export function Outlook({ className = '' } : { className?: string }) {
  return (
    <i className={className}>
      <svg
        style={{ height: '1em', verticalAlign: '-.125em', width: '1em' }} // Pulled from Font Awesome
        viewBox="50 50 300 300"
      >
        <g>
          <path d="M332.57,128.095H221.592V59.393L53.99,88.835v227.999l167.602,28.688v-64.926H332.57 c4.529,0,7.55-3.774,7.55-7.55V135.643C340.12,131.114,337.1,128.095,332.57,128.095 M132.507,254.172 c-55.112-3.774-52.849-104.185,1.509-107.204C193.656,143.947,192.147,257.947,132.507,254.172 M331.061,270.78H221.592v-86.064 l33.973,32.463c1.511,1.511,3.02,2.265,5.284,2.265c2.266,0,3.774-0.754,5.285-2.265l64.927-60.396V270.78z M331.061,143.947 l-70.212,64.928l-39.257-36.993v-34.729h108.713v6.795H331.061z" fill="currentColor" />
          <path d="M134.016,167.352c-29.443,1.511-30.198,65.683-0.755,66.437 C164.214,235.299,164.214,165.842,134.016,167.352" fill="currentColor" />
        </g>
      </svg>
    </i>
  )
}
