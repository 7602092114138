import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';

import { surveyPropType } from '../../prop_types';

export function Errors({ skipLogic, survey }) {
  const hasConditionErrors = skipLogic.conditionIds.find(id => (
    !!Object.keys(survey.skipLogicConditions[id].errors).length
  ));

  const hasErrors = !!Object.keys(skipLogic.errors).length;

  if (!hasErrors && !hasConditionErrors) return null;

  return (
    <div className="SkipLogicModal__skip-logic__row">
      <div className="SkipLogicModal__skip-logic__row__prefix" />
      <div
        className={classNames(
          'SkipLogicModal__skip-logic__row__control',
          'invalid-feedback',
          'force-display',
        )}
      >
        {hasConditionErrors && <p>Conditions are invalid</p>}
        {hasErrors && Object.keys(skipLogic.errors).map(
          key => <p key={key}>{skipLogic.errors[key]}</p>,
        )}
      </div>
    </div>
  );
}

Errors.propTypes = {
  skipLogic: propTypes.shape({
    conditionIds: propTypes.array.isRequired,
    errors: propTypes.shape({
      text: propTypes.string,
    }).isRequired,
  }).isRequired,
  survey: surveyPropType.isRequired,
};
