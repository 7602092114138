import * as propTypes from 'lib/prop_types';

export const uUIDPropType = propTypes.string;

// NOTE: This is supposed to encompass a survey passed to SurveyBuilder.
// This should be what the server passes back to the view, not what the views use internally.
export const surveyPropType = propTypes.shape({
  accessCode: propTypes.string,
  answers: propTypes.objectOf(
    propTypes.shape({
      errors: propTypes.objectOf(propTypes.string).isRequired,
      qualifyLogic: propTypes.string,
      responseClass: propTypes.string,
      text: propTypes.string,
      uuid: uUIDPropType.isRequired,
    }),
  ).isRequired,
  errors: propTypes.objectOf(propTypes.string).isRequired,
  questions: propTypes.objectOf(
    propTypes.shape({
      answerIds: propTypes.arrayOf(uUIDPropType),
      errors: propTypes.objectOf(propTypes.string).isRequired,
      helpText: propTypes.string,
      isKey: propTypes.bool,
      isMandatory: propTypes.bool,
      participantPopulationAttributeId: propTypes.oneOfType([
        propTypes.number,
        propTypes.string,
      ]),
      pick: propTypes.string,
      questionIds: propTypes.arrayOf(propTypes.string),
      text: propTypes.string,
      uuid: uUIDPropType.isRequired,
    }),
  ).isRequired,
  sectionIds: propTypes.arrayOf(uUIDPropType).isRequired,
  sections: propTypes.objectOf(
    propTypes.shape({
      errors: propTypes.objectOf(propTypes.string).isRequired,
      questionIds: propTypes.arrayOf(uUIDPropType).isRequired,
      skipLogicIds: propTypes.arrayOf(uUIDPropType).isRequired,
      title: propTypes.string,
      uuid: uUIDPropType.isRequired,
    }),
  ).isRequired,
  skipLogicConditions: propTypes.objectOf(
    propTypes.shape({
      answerId: uUIDPropType,
      errors: propTypes.objectOf(propTypes.string).isRequired,
      operator: propTypes.string,
      questionId: uUIDPropType,
      uuid: uUIDPropType.isRequired,
    }),
  ).isRequired,
  skipLogics: propTypes.objectOf(
    propTypes.shape({
      conditionIds: propTypes.arrayOf(uUIDPropType).isRequired,
      errors: propTypes.objectOf(propTypes.string).isRequired,
      joinMethod: propTypes.string,
      targetSectionId: uUIDPropType,
      uuid: uUIDPropType.isRequired,
    }),
  ).isRequired,
  title: propTypes.string,
});
