/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useContext } from 'react';
import propTypes from 'prop-types';

import { useTracking } from 'react-tracking';

import { AuthUserContext } from 'common/authorization';

import { trackingEvents } from 'lib/analytics';

export function Required({ isMandatory, onChange }) {
  const { account } = useContext(AuthUserContext);
  const { trackEvent } = useTracking();

  // Prevent a click from triggering parent event
  const handleClick = event => event.stopPropagation();
  const handleChange = ({ currentTarget: { checked } }) => {
    onChange({ isMandatory: checked });

    trackEvent({
      event: trackingEvents.SURVEY_REQUIRED_CHECKBOX_CLICKED,
      is_enabled: checked,
      account_id: account?.id,
    });
  };

  return (
    <label onClick={handleClick}>
      <input
        checked={isMandatory}
        type="checkbox"
        onChange={handleChange}
      />
      Required
    </label>
  );
}

Required.propTypes = {
  isMandatory: propTypes.bool.isRequired,
  onChange: propTypes.func.isRequired,
};

/* eslint-enable */
