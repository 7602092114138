import { ALL_TIME_ZONES, NON_US_TIME_ZONES, US_TIME_ZONES } from 'lib/generated_constants/time_zones';

type TimeZones = typeof ALL_TIME_ZONES;
type TimeZoneSelectOption = {
  label: string,
  value: string,
  utcOffsetSeconds: number
};

const toSelectOptions = (timeZones: TimeZones): TimeZoneSelectOption[] => (
  timeZones.map(zone => ({
    label: zone.displayName,
    value: zone.name,
    utcOffsetSeconds: zone.utcOffsetSeconds,
  }))
);

export const NON_US_TIME_ZONES_OPTIONS = toSelectOptions(NON_US_TIME_ZONES);
export const US_TIME_ZONES_OPTIONS = toSelectOptions(US_TIME_ZONES);
