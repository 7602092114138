/* eslint-disable react-hooks/exhaustive-deps */
import { useContext } from 'react';

import SurveyContext from '../../context';
import { useAutosizeTextArea } from './useAutosizeTextArea';

export const useAutosizeGridTextArea = (
  textAreaRef,
  value,
  questionGroupId,
) => {
  const { survey } = useContext(SurveyContext);

  const questionGroup = survey.questions[questionGroupId];
  const firstQuestion = survey.questions[questionGroup.questionIds[0]];

  const totalAnswersInQuestionGroup = firstQuestion.answerIds.length;

  useAutosizeTextArea({
    textAreaRef,
    value,
    dependencies: [totalAnswersInQuestionGroup],
  });
};
