import React, { useMemo, useCallback } from 'react';
import propTypes from 'prop-types';
import { SingleSelect } from '@user-interviews/ui-design-system';
import classNames from 'classnames';

import * as Constants from '../../../../constants';

import { AnswerSelect } from './answer_select';
import { OperatorSelect } from './operator_select';

export function Controls({
  isReadonly,
  skipLogicCondition,
  skipLogicOptions,
  survey,
  onUpdate,
}) {
  const handleQuestionChanged = useCallback(
    event => onUpdate({
      answerId: '',
      questionId: event.value,
    }),
    [onUpdate],
  );

  const conditionQuestion = survey.questions[skipLogicCondition.questionId];

  const controls = useMemo(() => {
    if (!conditionQuestion) {
      return (
        <>
          <select className="form-select" disabled />
          <select className="form-select" disabled />
        </>
      );
    }

    if (conditionQuestion.pick === Constants.QUESTION_PICK_ANY) {
      return (
        <>
          <AnswerSelect
            answerIds={conditionQuestion.answerIds}
            isReadonly={isReadonly}
            skipLogicCondition={skipLogicCondition}
            onUpdate={onUpdate}
          />
          <OperatorSelect
            isReadonly={isReadonly}
            questionType={conditionQuestion.pick}
            skipLogicCondition={skipLogicCondition}
            onUpdate={onUpdate}
          />
        </>
      );
    }

    return (
      <>
        <OperatorSelect
          isReadonly={isReadonly}
          questionType={conditionQuestion.pick}
          skipLogicCondition={skipLogicCondition}
          onUpdate={onUpdate}
        />
        <AnswerSelect
          answerIds={conditionQuestion.answerIds}
          isReadonly={isReadonly}
          skipLogicCondition={skipLogicCondition}
          onUpdate={onUpdate}
        />
      </>
    );
  }, [
    conditionQuestion,
    isReadonly,
    onUpdate,
    skipLogicCondition,
  ]);

  return (
    <div className="SkipLogicModal__skip-logic__row__controls">
      <SingleSelect
        aria-label="Select question"
        className={classNames(
          'single-select',
          { 'is-invalid': skipLogicCondition.errors.question },
        )}
        disabled={isReadonly}
        inputId="question-select"
        modal
        options={skipLogicOptions}
        value={{ label: conditionQuestion?.text, value: skipLogicCondition.questionId }}
        onChange={handleQuestionChanged}
      />
      {controls}
    </div>
  );
}

Controls.propTypes = {
  isReadonly: propTypes.bool.isRequired,
  skipLogicCondition: propTypes.object.isRequired,
  skipLogicOptions: propTypes.array.isRequired,
  survey: propTypes.object.isRequired,
  onUpdate: propTypes.func.isRequired,
};
