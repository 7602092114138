import React, { useContext } from 'react';
import propTypes from 'prop-types';

import { useTracking } from 'react-tracking';

import { AuthUserContext } from 'common/authorization';

import { trackingEvents } from 'lib/analytics';

import SurveyContext from '../../context';

export function OtherOptionCheckbox({
  checked,
  otherAnswerUuid,
  questionUuid,
}) {
  const { account } = useContext(AuthUserContext);
  const { answerFunctions: { addOther, remove } } = useContext(SurveyContext);
  const { trackEvent } = useTracking();

  const handleClick = event => event.stopPropagation();

  const handleChange = ({ currentTarget }) => {
    if (currentTarget.checked) {
      addOther(questionUuid);
    } else {
      remove(questionUuid, otherAnswerUuid);
    }

    trackEvent({
      event: trackingEvents.SURVEY_OTHER_OPTION_CHECKBOX_CLICKED,
      is_enabled: currentTarget.checked,
      account_id: account?.id,
    });
  };

  return (
    /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
    /* eslint-disable jsx-a11y/click-events-have-key-events */
    <label onClick={handleClick}>
      <input
        checked={checked}
        type="checkbox"
        onChange={handleChange}
      />
      Add "Other" option
    </label>
  );
}

OtherOptionCheckbox.propTypes = {
  checked: propTypes.bool.isRequired,
  otherAnswerUuid: propTypes.string,
  questionUuid: propTypes.string.isRequired,
};

OtherOptionCheckbox.defaultProps = {
  otherAnswerUuid: undefined,
};
