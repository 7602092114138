import React from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@user-interviews/ui-design-system';

import * as propTypes from 'lib/prop_types';

export function ConfirmDeleteModal({
  open,
  onRequestClose,
  onConfirm,
}) {
  return (
    <Modal
      className="Comment__confirm-modal"
      isOpen={open}
      onRequestClose={onRequestClose}
    >
      <ModalHeader
        title="Delete project note?"
        titleId="Comment__confirm-modal"
        variant="danger"
        onRequestClose={onRequestClose}
      />
      <ModalBody>
        This will permanently delete the selected project note. This action cannot be undone.
      </ModalBody>
      <ModalFooter
        onRequestClose={onRequestClose}
      >
        <Button variant="primary" onClick={onConfirm}>
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  )
}

ConfirmDeleteModal.propTypes = {
  open: propTypes.bool.isRequired,
  onConfirm: propTypes.func.isRequired,
  onRequestClose: propTypes.func.isRequired,
};
