import React, { useState, useCallback } from 'react';
import { Manager, Reference, Popper } from 'react-popper';
import * as propTypes from 'lib/prop_types';

function ProjectSummaryTooltip({ summaries, children, className }) {
  const [showPopper, setShowPopper] = useState(false);

  const handleShowTooltip = useCallback(() => {
    setShowPopper(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPopper]);

  const handleHideTooltip = useCallback(() => {
    setShowPopper(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPopper]);

  return (
    <Manager>
      <Reference>
        {({ ref }) => (
          <span
            aria-hidden="true"
            className={className}
            ref={ref}
            onMouseEnter={handleShowTooltip}
            onMouseLeave={handleHideTooltip}
          >
            {children}
          </span>
        )}
      </Reference>
      {showPopper && (
        <Popper placement="top">
          {
            ({ ref, style, placement }) => (
              <div
                className="ui-popper ui-popper--dark"
                data-placement={placement}
                ref={ref}
                style={{ zIndex: 100, ...style }}
              >
                <ul className="list-unstyled">
                  <li>Requested Participants: {summaries.requestedParticipantCount}</li>
                  <li>Originally Requested: {summaries.originalRequestedParticipantCount}</li>
                  <li>
                    Needs Review: {summaries.needsReviewParticipantCount}
                  </li>
                  <li>Unavailable: {summaries.unavailableParticipantCount}</li>
                  <li>Qualified: {summaries.qualifiedParticipantCount}</li>
                  <li>Approved: {summaries.approvedParticipantCount}</li>
                  <li>Confirmed: {summaries.confirmedParticipantCount}</li>
                </ul>
              </div>
            )
          }
        </Popper>
      )}
    </Manager>
  );
}

ProjectSummaryTooltip.propTypes = {
  className: propTypes.string,
  summaries: propTypes.shape({
    approvedParticipantCount: propTypes.number,
    confirmedParticipantCount: propTypes.number,
    needsReviewParticipantCount: propTypes.number,
    originalRequestedParticipantCount: propTypes.number,
    prequalifiedParticipantCount: propTypes.number,
    qualifiedParticipantCount: propTypes.number,
    requestedParticipantCount: propTypes.number,
    unavailableParticipantCount: propTypes.number,
  }),
};

ProjectSummaryTooltip.defaultProps = {
  className: '',
  summaries: {},
};

export default ProjectSummaryTooltip;
