import React, { useContext, useMemo, useCallback } from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';

import SurveyContext from '../../../../context';

export function AnswerSelect({
  answerIds,
  isReadonly,
  skipLogicCondition,
  onUpdate,
}) {
  const { survey } = useContext(SurveyContext);

  const answers = useMemo(() => {
    if (!answerIds) return null;

    return answerIds.map((answerId) => {
      const answer = survey.answers[answerId];

      // TODO: Fix holistically
      if (!answer) return null;

      return <option key={answer.uuid} value={answer.uuid}>{answer.text}</option>;
    })
  }, [survey.answers, answerIds]);

  const handleChange = useCallback(
    event => onUpdate({ answerId: event.target.value }),
    [onUpdate],
  );

  return (
    <select
      className={classNames(
        'form-select',
        { 'is-invalid': skipLogicCondition.errors.answer },
      )}
      disabled={isReadonly}
      value={skipLogicCondition.answerId || ''}
      onChange={handleChange}
    >
      <option value="" />

      {answers}
    </select>
  );
}

AnswerSelect.propTypes = {
  answerIds: propTypes.array.isRequired,
  isReadonly: propTypes.bool.isRequired,
  skipLogicCondition: propTypes.object.isRequired,
  onUpdate: propTypes.func.isRequired,
};
