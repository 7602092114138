export const CONDITION_OPERATOR_EQL = '==';
export const CONDITION_OPERATOR_NOT_EQL = '!=';

export const JOIN_METHOD_OR = 'or';
export const JOIN_METHOD_AND = 'and';

export const OPT_IN_FORM_TYPE = 'opt-in';
export const SCREENER_FORM_TYPE = 'screener';

export * from 'lib/generated_constants/surveys';
