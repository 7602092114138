import React, { PureComponent } from 'react';
import * as propTypes from 'lib/prop_types';
import pluralize from 'pluralize';
import {
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
} from '@user-interviews/ui-design-system';

import * as routes from 'lib/routes';

function EditEventModalFooter({ onRequestClose, onUpdateClicked }) {
  return (
    <ModalFooter
      dismissButtonText="Close"
      onRequestClose={onRequestClose}
    >
      <button
        className="btn btn-primary"
        type="button"
        onClick={onUpdateClicked}
      >
        Update
      </button>
    </ModalFooter>
  )
}

EditEventModalFooter.propTypes = {
  onRequestClose: propTypes.func.isRequired,
  onUpdateClicked: propTypes.func.isRequired,
};

export class EditEventModalContent extends PureComponent {
  static propTypes = {
    isOneOnOne: propTypes.bool.isRequired,
    numScheduled: propTypes.number.isRequired,
    numSlots: propTypes.number.isRequired,
    onRequestClose: propTypes.func.isRequired,
    onUpdate: propTypes.func.isRequired,
  };

  state = { numSlots: this.props.numSlots };

  handleNumSlotsChanged = (event) => {
    this.setState({
      error: undefined,
      numSlots: Number.parseInt(event.target.value) || '',
    });
  };

  handleUpdateClicked = () => {
    if (this.state.numSlots <= 0) {
      this.setState({ error: 'Sessions need to have at least one available slot.' });
      return;
    }

    if (this.state.numSlots < this.props.numScheduled) {
      this.setState({
        error: `
          This session already has
          ${this.props.numScheduled}
          ${pluralize('participant', this.props.numScheduled)}
          scheduled, cannot reduce the number of slots below that number.`,
      });
      return;
    }

    this.props.onUpdate({ numSlots: this.state.numSlots });
  };

  get description() {
    if (!this.props.isOneOnOne) return undefined;

    return (
      <>
        Warning: if you increase this number, multiple participants can join the same session. To
        create multiple sessions at the same time,{' '}
        <a
          href={routes.interviews_at_the_same_time_support_path()}
          rel="noopener noreferrer"
          target="_blank"
        >
          add more sessions to the calendar
        </a> instead.
      </>
    );
  }

  render() {
    const { numSlots, error } = this.state;
    return (
      <>
        <ModalBody className="form-well vertical">
          <FormGroup
            description={this.description}
            errors={{ numSlots: error }}
            inputKey="numSlots"
            label="How many participants should be allowed to sign up for this session?"
          >
            <input
              className="form-control"
              min={this.props.numScheduled}
              type="number"
              value={numSlots}
              onChange={this.handleNumSlotsChanged}
            />
          </FormGroup>
        </ModalBody>
        <EditEventModalFooter
          onRequestClose={this.props.onRequestClose}
          onUpdateClicked={this.handleUpdateClicked}
        />
      </>
    );
  }
}

function EditEventModal({ isOpen, onRequestClose, ...props }) {
  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      {isOpen && (
      <EditEventModalContent {...props} onRequestClose={onRequestClose} />
      )}
    </Modal>
  )
}

EditEventModal.propTypes = {
  isOpen: propTypes.bool.isRequired,
  onRequestClose: propTypes.func.isRequired,
};

export default EditEventModal;
