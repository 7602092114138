import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { SingleSelect } from '@user-interviews/ui-design-system';

import { ALL_TIME_ZONES } from 'lib/generated_constants/time_zones';
import * as propTypes from 'lib/prop_types';

import { NON_US_TIME_ZONES_OPTIONS, US_TIME_ZONES_OPTIONS } from './constants/timezone_options';

export const systemTimezone = (() => {
  const systemTimezoneOffsetSeconds = new Date().getTimezoneOffset() * -60;
  const systemTimezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const matchingTimezone = (
    _.find(ALL_TIME_ZONES, { name: systemTimezoneName }) ||
    _.find(ALL_TIME_ZONES, { utcOffsetSeconds: systemTimezoneOffsetSeconds })
  )?.name;

  return matchingTimezone || systemTimezoneName;
})();

// Disabled when adding max-props rule.
// Future work should stay under 6 props
// eslint-disable-next-line local-rules/max-props
export function TimezoneSelect({
  className = '',
  defaultValue,
  id,
  menuPlacement,
  modal,
  name,
  onSelectChange,
  useSystemFallback = false,
  value,
  ...props
}) {
  const selectOptions = US_TIME_ZONES_OPTIONS.concat(
    [{ label: '------------', isDisabled: true }],
    NON_US_TIME_ZONES_OPTIONS,
  );

  const findSelectOption = optionValue => (
    selectOptions.find((option) => option.value === optionValue)
  );

  const defaultSelected = () => {
    if (defaultValue) {
      return findSelectOption(defaultValue);
    }

    // If not provided a default try to pull the timezone locally if fallback is enabled
    if (useSystemFallback) {
      return findSelectOption(systemTimezone);
    }

    return undefined;
  };

  const controlledInputValue = value ? findSelectOption(value) : undefined;

  const handleChange = (option) => {
    if (onSelectChange) {
      onSelectChange({ name, value: option.value });
    }
  };

  return (
    <SingleSelect
      {...props}
      aria-label="select timezone"
      className={classNames('TimezoneSelect', className)}
      defaultValue={defaultSelected}
      inputId={id}
      menuPlacement={menuPlacement}
      modal={modal}
      name={name}
      options={selectOptions}
      onChange={handleChange}
      {...(controlledInputValue && { value: controlledInputValue })}
    />
  );
}

TimezoneSelect.propTypes = {
  className: propTypes.string,
  defaultValue: propTypes.string,
  id: propTypes.string,
  menuPlacement: propTypes.string,
  modal: propTypes.bool,
  name: propTypes.string,
  useSystemFallback: propTypes.bool,
  value: propTypes.string,
  onSelectChange: propTypes.func,
};

TimezoneSelect.defaultProps = {
  className: undefined,
  defaultValue: undefined,
  value: undefined,
  id: undefined,
  menuPlacement: undefined,
  modal: false,
  name: undefined,
  useSystemFallback: false,
  onSelectChange: undefined,
};
