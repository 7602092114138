import React, { PureComponent } from 'react';
import * as propTypes from 'lib/prop_types';
import { JsonApiDataStore } from 'jsonapi-datastore';

import {
  Drawer,
  DrawerHeader,
  LoadingOverlay,
} from '@user-interviews/ui-design-system';

import { ENTER } from 'lib/keycodes';
import {
  track,
  trackingEvents,
  trackingPropsShape,
} from 'lib/analytics';

import { Comment } from './comment';
import { ConfirmDeleteModal } from './confirm_delete_modal';
import { EditControl } from './edit_control';
import { Empty } from './empty';
import styles from './comments.module.scss';

// TODO: remove this unused component
class CommentsComponent extends PureComponent {
  static propTypes = {
    anchorId: propTypes.string,
    commenters: propTypes.arrayOf(
      propTypes.shape({ commenterId: propTypes.string }),
    ),
    comments: propTypes.array.isRequired,
    inviteDomain: propTypes.string,
    loading: propTypes.bool.isRequired,
    pageName: propTypes.string.isRequired,
    visible: propTypes.bool.isRequired,
    onCreateComment: propTypes.func.isRequired,
    onDeleteComment: propTypes.func.isRequired,
    onInvite: propTypes.func,
    onRequestClose: propTypes.func.isRequired,
    onUpdateComment: propTypes.func.isRequired,
    ...trackingPropsShape,
  };

  static defaultProps = {
    anchorId: undefined,
    commenters: [],
    inviteDomain: 'example.com',
    onInvite: undefined,
  };

  constructor(props) {
    super(props);

    this.state = {
      commentAnchor: props.anchorId,
      commentToDestroy: null,
      newComment: '',
    };
    // eslint-disable-next-line react/no-unused-class-component-methods
    this.store = new JsonApiDataStore();
    this.commentList = React.createRef();
  }

  createComment = async () => {
    if (!this.state.newComment) return;

    await this.props.onCreateComment(this.state.newComment);
    this.setState({ newComment: '' }, this.clearFocusedComment);
  };

  handleCommentKeyDown = (event) => {
    if (event.keyCode !== ENTER || event.shiftKey) return;

    event.preventDefault();
    this.createComment();
  };

  handleCommentInputChange = (event) => {
    this.setState({ newComment: event.target.value });
  };

  handleCommentDelete = async () => {
    if (!this.state.commentToDestroy) return;

    await this.props.onDeleteComment(this.state.commentToDestroy);

    this.setState({ commentToDestroy: null });
  };

  onCommentDelete = comment => (
    this.setState({ commentToDestroy: comment })
  );

  unsetCommentToDestroy = () => {
    this.setState({ commentToDestroy: null });
  };

  onOpen = () => {
    this.props.tracking.trackEvent({
      event: trackingEvents.COMMENTS_OPENED,
      page_name: this.props.pageName,
    });

    this.scrollIntoView();
  };

  onClose = () => {
    this.props.tracking.trackEvent({
      event: trackingEvents.COMMENTS_CLOSED,
      page_name: this.props.pageName,
    });

    this.clearFocusedComment();
  };

  get commenters() {
    return this.props.commenters.reduce((acc, commenter) => {
      acc[commenter.commenterId] = commenter;
      return acc;
    }, {});
  }

  get focusedComment() {
    if (!this.state.commentAnchor) return false;

    return this.commentList.current.querySelector(`#${this.state.commentAnchor}`);
  }

  get hasComments() {
    return !!this.props.comments.length;
  }

  clearFocusedComment() {
    if (!this.focusedComment) return;

    window.history.replaceState(
      {},
      document.title,
      window.location.pathname + window.location.search,
    );
    this.setState({ commentAnchor: null }, this.scrollToBottom);
  }

  scrollIntoView() {
    return this.focusedComment ? this.scrollToComment() : this.scrollToBottom();
  }

  scrollToComment() {
    if (!this.focusedComment) return;

    this.focusedComment.scrollIntoView({
      block: 'center',
      inline: 'nearest',
      behavior: 'smooth',
    });
  }

  scrollToBottom() {
    if (!this.commentList.current) return;

    this.commentList.current.lastChild.scrollIntoView({
      block: 'end',
      inline: 'nearest',
      behavior: 'smooth',
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visible && !this.props.visible) {
      this.onClose();
    }

    if (!prevProps.visible && this.props.visible) {
      this.onOpen();
    }
    if (!this.state.newComment) {
      this.scrollIntoView();
    }
  }

  renderComments() {
    return this.props.comments.map(comment => (
      <Comment
        comment={comment}
        commenters={this.commenters}
        focused={this.state.commentAnchor === `comment-${comment.id}`}
        inviteDomain={this.props.inviteDomain}
        key={comment.id}
        onDelete={this.onCommentDelete}
        onInvite={this.props.onInvite}
        onUpdate={this.props.onUpdateComment}
      />
    ));
  }

  render() {
    return (
      <Drawer
        className={styles.Comments}
        visible={this.props.visible}
        onRequestClose={this.props.onRequestClose}
      >
        <DrawerHeader
          bordered={false}
          title="Comments"
          onRequestClose={this.props.onRequestClose}
        />
        <LoadingOverlay visible={this.props.loading} />
        <ConfirmDeleteModal
          open={!!this.state.commentToDestroy}
          onConfirm={this.handleCommentDelete}
          onRequestClose={this.unsetCommentToDestroy}
        />

        <ul className={styles.Comments__list} ref={this.commentList}>
          {this.hasComments ? this.renderComments() : <Empty />}
        </ul>

        <div className={styles.Comments__new}>
          <EditControl
            commenters={this.commenters}
            inviteDomain={this.props.inviteDomain}
            value={this.state.newComment}
            onChange={this.handleCommentInputChange}
            onInvite={this.props.onInvite}
            onKeyDown={this.handleCommentKeyDown}
          />
        </div>
      </Drawer>
    );
  }
}

export const Comments = track()(CommentsComponent);
