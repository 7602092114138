import React, { useContext, useCallback } from 'react';
import propTypes from 'prop-types';

import { NEW_LINE_REGEX } from 'lib/surveys/builder/utilities';

import * as Constants from '../../constants';
import SurveyContext from '../../context';

import { Editing } from './editing';
import { Pick } from './pick';
import { PickNone } from './pick_none';
import { OtherAnswer } from './other_answer';

import './answer.scss';

export function Answer({
  answer,
  index,
  isEditing,
  isRemovableAnswer,
  questionId,
  type,
}) {
  const {
    answerFunctions: {
      add: addAnswer,
      addBatch: addAnswers,
      remove: removeAnswer,
      update: updateAnswer,
    },
  } = useContext(SurveyContext);

  const handleChange = useCallback((changes) => {
    const answers = changes.text?.split(NEW_LINE_REGEX).filter(text => !!text);

    if (changes.text && answers.length > 1) {
      addAnswers(questionId, answer.uuid, answers);
    } else {
      updateAnswer(answer.uuid, changes);
    }
  }, [answer.uuid, addAnswers, questionId, updateAnswer]);

  if (answer.responseClass === Constants.RESPONSE_CLASS_OTHER_AND_STRING) {
    return (
      <OtherAnswer
        answer={answer}
        index={index}
        isEditing={isEditing}
        questionType={type}
        onChange={handleChange}
      />
    );
  }

  if (type === Constants.QUESTION_PICK_NONE) {
    return <PickNone answer={answer} />;
  }

  return (
    <>
      <Editing
        answer={answer}
        index={index}
        isEditing={isEditing}
        isRemovableAnswer={isRemovableAnswer}
        questionId={questionId}
        questionType={type}
        onAdd={addAnswer}
        onAddBatch={addAnswers}
        onChange={handleChange}
        onRemove={removeAnswer}
      />
      <Pick
        answer={answer}
        isEditing={isEditing}
        questionType={type}
      />
    </>
  );
}

Answer.propTypes = {
  answer: propTypes.shape({
    errors: propTypes.object.isRequired,
    responseClass: propTypes.string,
    text: propTypes.string,
    uuid: propTypes.string.isRequired,
  }).isRequired,
  index: propTypes.number.isRequired,
  isEditing: propTypes.bool.isRequired,
  isRemovableAnswer: propTypes.bool.isRequired,
  questionId: propTypes.string.isRequired,
  type: propTypes.string.isRequired,
};
