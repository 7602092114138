import React from 'react';
import * as propTypes from 'lib/prop_types';

/* eslint-disable max-len */
export function UserZoom({ className }) {
  return (
    <svg
      className={`UserZoom ${className}`}
      fill="none"
      viewBox="40 40 315 315"
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M203.592 328.04c68.179 0 123.45-55.27 123.45-123.449 0-68.178-55.271-123.45-123.45-123.45-68.18 0-123.45 55.272-123.45 123.45s55.27 123.45 123.45 123.45"
          fill="#FF5036"
        />
        <path
          d="M203.592 196.227s-1.59-2.627-5.062-7.137c-3.472-4.51-8.827-10.901-16.355-18.43-8.354-8.353-19.3-12.537-30.248-12.55-10.95-.011-21.901 4.146-30.271 12.474-8.164 8.124-12.284 18.793-12.421 29.557a43.804 43.804 0 0 0 11.478 30.123c22.257 24.358 52.568 36.537 82.879 36.537 30.31 0 60.622-12.18 82.879-36.537a43.804 43.804 0 0 0 11.478-30.123c-.137-10.764-4.257-21.433-12.421-29.557-8.37-8.328-19.322-12.485-30.271-12.473-10.95.012-21.894 4.196-30.248 12.55-7.528 7.528-12.883 13.92-16.355 18.429-3.472 4.51-5.062 7.137-5.062 7.137"
          fill="#FFF"
        />
        <path
          d="M134.356 200.958c0 9.325 7.56 16.883 16.884 16.883 9.325 0 16.885-7.558 16.885-16.883 0-9.326-7.56-16.884-16.885-16.884-9.324 0-16.884 7.558-16.884 16.883M203.592 196.227c-13.055 13.055-13.055 34.22 0 47.276 13.055-13.055 13.055-34.221 0-47.276M272.892 200.958c0 9.325-7.56 16.883-16.885 16.883-9.325 0-16.885-7.558-16.885-16.883 0-9.326 7.56-16.884 16.885-16.884 9.325 0 16.885 7.558 16.885 16.883"
          fill="#000"
        />
      </g>
    </svg>
  )
}

UserZoom.propTypes = {
  className: propTypes.string,
};

UserZoom.defaultProps = {
  className: '',
};
