/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect } from 'react';

import Resize from 'lib/events/resize';

export const useAutosizeTextArea = ({
  textAreaRef,
  value,
  dependencies,
}) => {
  const resizeTextArea = useCallback(() => {
    if (textAreaRef) {
      const ref = textAreaRef;

      ref.style.height = '0px';

      const { scrollHeight } = ref;

      ref.style.height = `${scrollHeight}px`;
    }
  }, [textAreaRef])

  useEffect(() => {
    const resizeListener = Resize.listen(resizeTextArea);
    return () => Resize.cleanup(resizeListener);
  }, [textAreaRef]);

  useEffect(
    resizeTextArea,
    [textAreaRef, value, ...dependencies],
  );
};
