import React, { useContext } from 'react';
import propTypes from 'prop-types';

import { Flex } from '@user-interviews/ui-design-system';

import { isQuestionGroup, isPickOneOrAny } from 'lib/surveys/builder/utilities';

import { KeyQuestion } from './key_question';
import { OtherOptionCheckbox } from './other_option_checkbox';
import { Required } from './required';
import * as Constants from '../../constants';

import SurveyContext from '../../context';

import styles from './question_controls.module.scss';

export function QuestionControls({
  otherAnswer,
  question,
  pageTitle,
  position,
  onChange,
}) {
  const { formType } = useContext(SurveyContext)
  const isScreenerForm = formType === Constants.SCREENER_FORM_TYPE

  return (
    <Flex
      alignItems="center"
      className={styles.QuestionControls}
      container
      flexDirection="row"
    >
      <Required
        isMandatory={question.isMandatory}
        onChange={onChange}
      />
      {isPickOneOrAny(question) && !isQuestionGroup(question) && (
      <OtherOptionCheckbox
        checked={!!otherAnswer}
        otherAnswerUuid={otherAnswer?.uuid}
        questionUuid={question.uuid}
      />
      )}
      { isScreenerForm && (
      <KeyQuestion
        isKey={question.isKey}
        pageTitle={pageTitle}
        position={position}
        onChange={onChange}
      />
      )}
    </Flex>
  )
}

QuestionControls.propTypes = {
  otherAnswer: propTypes.shape({
    errors: propTypes.object.isRequired,
    responseClass: propTypes.string,
    text: propTypes.string,
    uuid: propTypes.string.isRequired,
  }),
  pageTitle: propTypes.string.isRequired,
  position: propTypes.number.isRequired,
  question: propTypes.shape({
    answerIds: propTypes.array,
    errors: propTypes.shape({
      text: propTypes.string,
    }).isRequired,
    isEditing: propTypes.bool,
    isKey: propTypes.bool.isRequired,
    isMandatory: propTypes.bool.isRequired,
    isNew: propTypes.bool,
    pick: propTypes.string.isRequired,
    text: propTypes.string.isRequired,
    uuid: propTypes.string.isRequired,
  }).isRequired,
  onChange: propTypes.func.isRequired,
};

QuestionControls.defaultProps = {
  otherAnswer: undefined,
};
