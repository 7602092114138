import React from 'react';
import propTypes from 'prop-types';

import { ModalBody, ModalFooter, ModalHeader } from '@user-interviews/ui-design-system';

import {
  ProviderOauthButton,
} from 'researcher/account_dashboard/pages/account_integrations_page/provider_oauth_button';

function SuccessfullyVerifiedTokenModalContents({
  onRequestClose, disabled, provider,
}) {
  return (
    <>
      <ModalHeader title="Successfully Verified!" onRequestClose={onRequestClose} />
      <ModalBody>
        <p>
          Now we'll ask you to log in to Qualtrics to connect your account and use it on your project!
        </p>
      </ModalBody>
      <ModalFooter dismissButtonText="Cancel" onRequestClose={onRequestClose}>
        <ProviderOauthButton
          ctaText="Continue to connect"
          disabled={disabled}
          provider={provider}
        />
      </ModalFooter>
    </>
  )
}

SuccessfullyVerifiedTokenModalContents.propTypes = {
  disabled: propTypes.bool.isRequired,
  provider: propTypes.object.isRequired,
  onRequestClose: propTypes.func.isRequired,
};

export default SuccessfullyVerifiedTokenModalContents;
