import React from 'react';
import * as propTypes from 'lib/prop_types';
import {
  Button,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from '@user-interviews/ui-design-system';

import { TrackedButton } from 'common/analytics';
import { TrackedModal } from 'common/modals';

import { trackingEvents } from 'lib/analytics';
import { PREMIUM_SURVEY_FEE } from 'lib/generated_constants/billing';
import {
  LegacyBundleMonthlyDisplayPrices,
  SubscriptionNames,
} from 'lib/generated_constants/subscriptions';

function SurveyUpgradeModal({
  isOpen,
  onRequestClose,
  onUpgradeClick,
}) {
  return (
    <TrackedModal
      className="SurveyUpgradeModal"
      event={trackingEvents.SURVEY_UPGRADE_MODAL_SHOWN}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <ModalHeader
        title="Upgrade project?"
        titleId="survey-upgrade-modal"
        onRequestClose={onRequestClose}
      />
      <ModalBody>
        <p>
          {/* UI-311 Another $ sign to change for other currencies */}
          Surveys with multiple pages add an extra ${PREMIUM_SURVEY_FEE} per
          completed participant session or are included in our subscription plans,
          starting at {LegacyBundleMonthlyDisplayPrices[SubscriptionNames.CONSUMER]} / month.
        </p>
        <p>
          Would you like to upgrade? You can choose pay as you go or subscription
          pricing in the final review step.
        </p>
      </ModalBody>
      <ModalFooter>
        <Button
          variant="link"
          onClick={onRequestClose}
        >
          Cancel
        </Button>
        <TrackedButton
          event={trackingEvents.SURVEY_UPGRADE_ACCEPTED}
          variant="primary"
          onClick={onUpgradeClick}
        >
          Upgrade project
        </TrackedButton>
      </ModalFooter>
    </TrackedModal>
  )
}

SurveyUpgradeModal.propTypes = {
  isOpen: propTypes.bool,
  onRequestClose: propTypes.func.isRequired,
  onUpgradeClick: propTypes.func.isRequired,
};

SurveyUpgradeModal.defaultProps = {
  isOpen: false,
};

export default SurveyUpgradeModal;
