import React from 'react';
import { Redirect } from 'react-router-dom';

import * as propTypes from 'lib/prop_types';

import useAuthorization from 'hooks/use_authorization';
import MATCH_TYPES from './match_types';

function Authorization({
  children,
  matchType,
  notTo,
  redirectPath,
  to,
}) {
  const isAuthorized = useAuthorization({ matchType, notTo, to });

  if (isAuthorized) {
    return children;
  }

  return redirectPath ? <Redirect to={redirectPath} /> : null;
}

Authorization.propTypes = {
  children: propTypes.node.isRequired,
  matchType: propTypes.oneOf(Object.values(MATCH_TYPES)),
  notTo: propTypes.oneOfType([
    propTypes.string,
    propTypes.arrayOf(propTypes.string),
  ]),
  redirectPath: propTypes.string,
  to: propTypes.oneOfType([
    propTypes.string,
    propTypes.arrayOf(propTypes.string),
  ]),
};

Authorization.defaultProps = {
  matchType: MATCH_TYPES.ALL,
  redirectPath: undefined,
  notTo: null,
  to: null,
};

export default Authorization;
