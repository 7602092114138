import React, {
  useContext,
  useCallback,
} from 'react';
import { DragDropContext } from 'react-beautiful-dnd';

import SurveyContext from '../context';
import { surveyPropType } from '../prop_types';

import { Section } from './section';
import { AddNewPageButton } from './section/add_new_page_button';

function Sections({ survey }) {
  const { sectionFunctions, questionFunctions, isReadonly } = useContext(SurveyContext);
  const hasSections = survey.sectionIds.length;

  const handleMove = useCallback((result) => {
    if (!result) return;
    questionFunctions.move(result);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionFunctions.move]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleAddSection = useCallback(() => sectionFunctions.add(null), [sectionFunctions.add]);

  return (
    <DragDropContext onDragEnd={handleMove}>
      {survey.sectionIds.map(sectionId => (
        <Section
          key={sectionId}
          section={survey.sections[sectionId]}
        />
      ))}

      {!isReadonly && !hasSections && (
        <AddNewPageButton
          onAddSection={handleAddSection}
        />
      )}
    </DragDropContext>
  );
}

Sections.propTypes = {
  survey: surveyPropType.isRequired,
};

export default Sections;
