import React, { useCallback } from 'react';
import propTypes from 'prop-types';

import * as Constants from '../../../constants';

export function ConditionSelect({
  isReadonly,
  joinMethod,
  position,
  onUpdate,
}) {
  const handleChange = useCallback(
    event => onUpdate({ joinMethod: event.target.value }),
    [onUpdate],
  );

  if (position === 0) {
    return (
      <b className="SkipLogicModal__skip-logic__row__prefix">
        If
      </b>
    );
  }

  return (
    <select
      className="form-select SkipLogicModal__skip-logic__row__prefix"
      disabled={isReadonly}
      value={joinMethod}
      onChange={handleChange}
    >
      <option value={Constants.JOIN_METHOD_OR}>OR</option>
      <option value={Constants.JOIN_METHOD_AND}>AND</option>
    </select>
  );
}

ConditionSelect.propTypes = {
  isReadonly: propTypes.bool.isRequired,
  joinMethod: propTypes.string,
  position: propTypes.number.isRequired,
  onUpdate: propTypes.func.isRequired,
};

ConditionSelect.defaultProps = {
  joinMethod: '',
};
