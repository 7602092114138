import React, { useState } from 'react';
import { useTracking } from 'react-tracking';
import {
  Form,
  FormGroup,
  Input,
  LoadingOverlay,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  MODAL_SIZES,
} from '@user-interviews/ui-design-system';

import { TrackedButton } from 'common/analytics';
import useHttp from 'hooks/use_http';

import { trackingEvents } from 'lib/analytics';
import * as propTypes from 'lib/prop_types';
import * as routes from 'lib/routes';

import SuccessfullyVerifiedTokenModalContents from './successfully_verified_modal_contents';
import NonBrandTokenErrorModalContents from './non_brand_token_error_modal_contents';

// TODO: move these somewhere that makes sense. Its unclear if this component is actually reusable
// so not putting a lot of effort into it now.
/* eslint-disable max-len */
const BRAND_ADMIN_URL = 'https://www.qualtrics.com/support/survey-platform/getting-started/help-and-feedback/#QualtricsAdministrator';
const LEARN_HOW_URL = 'https://www.qualtrics.com/support/integrations/api-integration/overview/#GeneratingAnAPIToken';
const SUPPORT_EMAIL = 'qualtrics@userinterviews.com';
/* eslint-enable max-len */

function APIKeyModal({
  accountId,
  disabled,
  provider,
  onSuccess,
}) {
  const { trackEvent } = useTracking();
  const { loading, post } = useHttp();

  const [errors, setErrors] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [key, setKey] = useState('');
  const [responseData, setResponseData] = useState();
  const [shouldShowNonBrandTokenError, setShouldShowNonBrandTokenError] = useState(false);
  const [successfullyVerified, setSuccessfullyVerified] = useState(false);

  const shouldShowDefaultContent = !successfullyVerified && !shouldShowNonBrandTokenError;

  const handleKeyChange = ({ currentTarget: { value } }) => setKey(value);
  const handleRequestClose = () => {
    setErrors({});
    setKey('');
    if (shouldShowNonBrandTokenError) setShouldShowNonBrandTokenError(false);
    setIsOpen(false);
  };
  const handleRequestOpen = () => setIsOpen(true);

  const handleError = (e) => {
    const fieldErrors = e.fieldErrors();
    // eslint TODO Alex D
    const [errorDetails = ''] = fieldErrors?.key; // eslint-disable-line no-unsafe-optional-chaining

    if (errorDetails?.includes('has insufficient permissions')) {
      trackEvent({
        event: trackingEvents.QUALTRICS_NON_BRAND_ADMIN_TOKEN_MODAL_CONTENTS_SHOWN,
        accountId,
        feature: 'Qualtrics',
      });
      setShouldShowNonBrandTokenError(true);
    }

    setErrors(fieldErrors);
  };
  const handleSuccess = (response) => {
    setResponseData(response);
    setSuccessfullyVerified(true);
  };

  const handleSuccessfullyVerifiedClose = async () => {
    await onSuccess(responseData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    await post(
      routes.api_account_api_keys_path(accountId),
      {
        data: { key },
        onSuccess: handleSuccess,
        onError: handleError,
      },
    );
  };

  return (
    <>
      <TrackedButton
        event={trackingEvents.CONNECT_WITH_INTEGRATION_CLICKED}
        eventData={{ provider: provider.value }}
        type="button"
        onClick={handleRequestOpen}
      >
        Connect
      </TrackedButton>
      <Modal
        {...{ isOpen }}
        aria={{ describedby: `api-key-modal-description`, labelledby: `api-key-modal-label` }}
        size={MODAL_SIZES.SMALL}
      >
        <LoadingOverlay visible={loading} />

        {shouldShowNonBrandTokenError && (
          <NonBrandTokenErrorModalContents
            onRequestClose={handleRequestClose}
          />
        )}

        {successfullyVerified && (
          <SuccessfullyVerifiedTokenModalContents
            disabled={disabled}
            provider={provider}
            onRequestClose={handleSuccessfullyVerifiedClose}
          />
        )}

        {shouldShowDefaultContent && (
          <>
            <ModalHeader
              title={`To connect with ${provider.name}, please enter your API token below.`}
              titleId="api-key-modal-label"
              onRequestClose={handleRequestClose}
            />
            <ModalBody>
              <p id="api-key-modal-description">
                Contact your&nbsp;
                <a href={BRAND_ADMIN_URL} rel="noopener noreferrer" target="_blank">
                  Qualtrics Brand Admin
                </a> to access the API token. If you're a Brand Admin,&nbsp;
                <a href={LEARN_HOW_URL} rel="noopener noreferrer" target="_blank">
                  learn how to find your API token here.
                </a>
              </p>
              <Form
                id="api-key-modal-form"
                onSubmit={handleSubmit}
              >
                <FormGroup
                  errors={errors}
                  inputKey="key"
                >
                  <Input
                    aria-label="API token"
                    id="api-key-modal-control"
                    name="key"
                    required
                    value={key}
                    onChange={handleKeyChange}
                  />
                </FormGroup>

                <p>
                  Still have questions? Contact&nbsp;
                  <a href={`mailto:${SUPPORT_EMAIL}`}>
                    {SUPPORT_EMAIL}
                  </a>
                </p>
              </Form>
            </ModalBody>
            <ModalFooter onRequestClose={handleRequestClose}>
              <TrackedButton
                disabled={!key.length}
                event={trackingEvents.API_KEY_FORM_SUBMITTED}
                eventData={{ provider: provider.value }}
                form="api-key-modal-form"
                type="submit"
              >
                Submit
              </TrackedButton>
            </ModalFooter>
          </>
        )}

      </Modal>
    </>
  );
}

APIKeyModal.propTypes = {
  accountId: propTypes.string.isRequired,
  disabled: propTypes.bool.isRequired,
  provider: propTypes.shape({
    name: propTypes.string.isRequired,
    value: propTypes.string.isRequired,
  }).isRequired,
  onSuccess: propTypes.func,
};

APIKeyModal.defaultProps = {
  onSuccess: async () => {}, // No-op
};

export default APIKeyModal;
