import React, {
  useContext,
  useMemo,
  useState,
} from 'react';
import { useTracking } from 'react-tracking';
import { useParams } from 'react-router-dom';

import {
  CreatableSelect, FormGroup, MessageTypes,
} from '@user-interviews/ui-design-system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from 'lib/font_awesome/solid';
import * as propTypes from 'lib/prop_types';
import { trackingEvents } from 'lib/analytics';

import { useCreatePopulationAttributeMutation } from 'api/population_attributes';
import { useFormContext } from 'react-hook-form';
import { ErrorRenderer } from 'lib/errors';
import SurveyContext from '../context.js';

import { SurveyFormQuestionIconLabel } from '../survey_form_question_icon_label';
import { SurveyNewAttributeModal } from './survey_new_attribute_modal';

export function MapAttribute({ attributes, question, questionType }) {
  const { survey, questionFunctions: { update: updateQuestion }, setToastMessage } = useContext(SurveyContext);
  const [newAttributeModalVisible, setNewAttributeModalVisible] = useState(false);
  const [newAttribute, setNewAttribute] = useState({});
  const { formState: { errors } } = useFormContext();

  const { trackEvent } = useTracking();

  const { participantPopulationId } = useParams();

  const [createParticipantPopulationAttribute] = useCreatePopulationAttributeMutation();
  const formatCreateLabel = inputValue => (
    <div>
      <FontAwesomeIcon className="icon-left" icon={faPlusCircle} />
      Create {inputValue || 'new'} attribute
    </div>
  );
  const isValidNewOption = () => true;

  const handleCreateAttribute = async () => {
    const { data: createdAttribute, error } = await createParticipantPopulationAttribute({
      participantPopulationId,
      name: newAttribute.name,
      type: newAttribute.type,
    });

    if (error) {
      setToastMessage({
        type: MessageTypes.ERROR,
        message: ErrorRenderer.compileRtkQueryErrorMessage(
          error,
        ),
      });
    } else {
      trackEvent({
        event: trackingEvents.SURVEY_PARTICIPANT_POPULATION_ATTRIBUTE_CREATED,
        participant_population_attribute_id: createdAttribute.data.id,
      });

      updateQuestion(
        question.uuid,
        { participantPopulationAttributeId: createdAttribute.data.id },
      );

      setNewAttributeModalVisible(false);
    }
  };

  const handleAttributeModalClose = () => {
    setNewAttributeModalVisible(false);
  };

  const handleNewAttributeChange = (newAttr) => {
    setNewAttribute(newAttr);
  };

  const handleNewAttributeModalOpen = (inputValue) => {
    setNewAttributeModalVisible(true);
    setNewAttribute({ name: inputValue });
  };

  const handleAttributeChange = (selectedOption) => {
    updateQuestion(
      question.uuid,
      { participantPopulationAttributeId: selectedOption.value },
    );

    trackEvent({
      event: trackingEvents.SURVEY_PARTICIPANT_POPULATION_ATTRIBUTE_SELECTED,
      participant_population_attribute_id: selectedOption.value,
    });
  };

  const mappedAttributeIds = useMemo(() => (
    Object.values(survey.questions).reduce(
      (acc, current) => (
        current && current.uuid !== question.uuid && current.participantPopulationAttributeId ?
          acc.concat(current.participantPopulationAttributeId.toString()) :
          acc
      ),
      [],
    )
  ), [survey.questions, question.uuid]);

  const attributeOptions = useMemo(() => (
    Object.values(attributes)
      .map((attribute) => ({
        label: attribute.name,
        value: attribute.id,
        isDisabled: mappedAttributeIds.includes(attribute.id),
        attribute,
      }))
  ), [mappedAttributeIds, attributes]);

  const selectedAttribute = useMemo(() => (
    question.participantPopulationAttributeId ?
      attributeOptions.find(
        attributeOption =>
          attributeOption.value ===
            question.participantPopulationAttributeId.toString(),
      ) :
      null // Fun fact -- this has to be null, not undefined, otherwise ReactSelect won't pick up the change
  ), [attributeOptions, question.participantPopulationAttributeId]);

  return (
    <>
      <SurveyNewAttributeModal
        attribute={newAttribute}
        questionType={questionType}
        visible={newAttributeModalVisible}
        onChange={handleNewAttributeChange}
        onClose={handleAttributeModalClose}
        onSave={handleCreateAttribute}
      />
      <FormGroup
        errors={errors}
        inputKey={`question-${question.uuid}-attribute`}
        label="Map to attribute"
        labelHtmlFor="new-question-attribute"
        labelTooltip="Map your custom questions to Hub attributes to collect data you can filter on later."
        required
      >
        <CreatableSelect
          allowCreateWhileLoading
          aria-label="Select or create a new attribute"
          className="Select"
          createOptionPosition="first"
          formatOptionLabel={({ value, attribute }) =>
            (attribute ? <SurveyFormQuestionIconLabel attribute={attribute} /> : formatCreateLabel(value))}
          inputId="new-question-attribute"
          isSearchable
          isValidNewOption={isValidNewOption}
          name="attribute"
          options={attributeOptions}
          placeholder="Select or create a new attribute"
          styles={{
            indicatorSeparator: (provided) => ({
              ...provided,
              width: 0,
            }),
          }}
          value={selectedAttribute}
          onChange={handleAttributeChange}
          onCreateOption={handleNewAttributeModalOpen}
        />
      </FormGroup>
    </>
  );
}

MapAttribute.propTypes = {
  attributes: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.string,
    }),
  ).isRequired,
  question: propTypes.shape({
    participantPopulationAttributeId: propTypes.oneOfType([
      propTypes.number,
      propTypes.string,
    ]),
    uuid: propTypes.string.isRequired,
  }).isRequired,
  questionType: propTypes.string.isRequired,
};
