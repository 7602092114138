import React, { useContext } from 'react';
import propTypes from 'prop-types';

import { TrackedIconButton } from 'common/analytics';
import { AuthUserContext } from 'common/authorization';

import { trackingEvents } from 'lib/analytics';

export function Actions({
  isRemovableQuestion,
  isVisible,
  questionUUID,
  sectionUUID,
  onDuplicate,
  onRemove,
}) {
  const { account } = useContext(AuthUserContext);

  if (!isVisible) return null;
  const handleDuplicate = (event) => {
    event.stopPropagation();

    onDuplicate(sectionUUID, questionUUID);
  };
  const handleRemove = (event) => {
    event.stopPropagation();

    onRemove(sectionUUID, questionUUID);
  };

  return (
    <>
      <TrackedIconButton
        action="COPY"
        ariaLabel="Duplicate Screener Question"
        event={trackingEvents.BUILD_PROJECT_SCREENER_QUESTION_DUPLICATED}
        onClick={handleDuplicate}
      />
      <TrackedIconButton
        action="DELETE"
        ariaLabel="Remove Screener Question"
        disabled={!isRemovableQuestion}
        event={trackingEvents.SURVEY_REMOVE_QUESTION}
        eventData={{
          account_id: account?.id,
        }}
        onClick={handleRemove}
      />
    </>
  );
}

Actions.propTypes = {
  isRemovableQuestion: propTypes.bool.isRequired,
  isVisible: propTypes.bool.isRequired,
  questionUUID: propTypes.string.isRequired,
  sectionUUID: propTypes.string.isRequired,
  onDuplicate: propTypes.func.isRequired,
  onRemove: propTypes.func.isRequired,
};
