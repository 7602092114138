import React from 'react';
import propTypes from 'prop-types';
import { Button } from '@user-interviews/ui-design-system';
import { faPlusCircle } from 'lib/font_awesome/regular';

import styles from './add_new_page_button.module.scss';

export function AddNewPageButton({ onAddSection }) {
  return (
    <div className={styles.AddNewPageButton}>
      <Button
        leadingIcon={faPlusCircle}
        variant="link"
        onClick={onAddSection}
      >
        Add new page
      </Button>
    </div>
  )
}

AddNewPageButton.propTypes = {
  onAddSection: propTypes.func.isRequired,
};
