import React, { useCallback, useContext } from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IconButton } from '@user-interviews/ui-design-system';
import { useController, useFormContext } from 'react-hook-form';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { faGripVertical } from 'lib/font_awesome/solid';

import { NEW_LINE_REGEX } from 'lib/surveys/builder/utilities';

import { SCREENER_FORM_TYPE } from 'common/components/survey_builder/constants';
import SurveyContext from 'common/components/survey_builder/context';
import { QualifyLogicSelect } from './qualify_logic_select';

import * as Constants from '../../constants';

import './editing.scss';

// Disabled when adding max-props rule.
// Future work should stay under 6 props
// eslint-disable-next-line local-rules/max-props
export function Editing({
  answer,
  index,
  isEditing,
  isRemovableAnswer,
  questionId,
  questionType,
  onAdd,
  onRemove,
  onChange,
}) {
  const isPickOne = questionType === Constants.QUESTION_PICK_ONE;
  const isBoolean = questionType === Constants.QUESTION_BOOLEAN;

  const {
    formState: { errors },
  } = useFormContext();
  const {
    formType,
  } = useContext(SurveyContext);
  const isScreenerForm = formType === SCREENER_FORM_TYPE;
  const answerName = `answer.${answer.uuid}`;
  const { field: answerInput } = useController({
    defaultValue: answer.text,
    name: answerName,
    rules: { required: true },
  });

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id: answer.uuid,
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  const handleClick = event => event.stopPropagation();

  const handleAdd = useCallback((event) => {
    event.stopPropagation();

    onAdd(questionId, answer.uuid);
  }, [onAdd, questionId, answer.uuid]);

  const handleRemove = useCallback((event) => {
    event.stopPropagation();

    onRemove(questionId, answer.uuid);
  }, [onRemove, questionId, answer.uuid]);

  const handleTextChange = useCallback((event) => {
    const eventValue = event.target.value;

    const answers = eventValue.split(NEW_LINE_REGEX);

    if (answers.length > 1) {
      answerInput.onChange(answers[0]);
    } else {
      answerInput.onChange(eventValue);
    }

    onChange({ text: eventValue });
  }, [onChange, answerInput]);

  const hasErrors = errors?.answer?.[answer.uuid] || Object.keys(answer.errors).length;

  const optionNumber = (index || 0) + 1;

  return (
    /* eslint-disable jsx-a11y/no-static-element-interactions */
    /* eslint-disable jsx-a11y/click-events-have-key-events */
    <div
      className="SurveyBuilderRow editing"
      id={answer.uuid}
      ref={setNodeRef}
      style={isEditing ? style : { display: 'none' }}
    >
      <div className="SurveyBuilderRow__text">
        <div className="SurveyBuilderRow__text__answer">
          <div
            className="drag-handle-wrapper"
            id={`reorder-${answer.uuid}`}
            {...attributes}
            {...listeners}
          >
            <FontAwesomeIcon
              icon={faGripVertical}
              size="sm"
            />
          </div>
          <div className="checkbox-wrapper">
            <input
              aria-label="answer selected preview"
              disabled
              type={isPickOne || isBoolean ? 'radio' : 'checkbox'}
            />
          </div>
          <div className="answer-wrapper">
            <textarea
              /* We are saving data in both react-hook-form and survey builder context so we can
              validate the inputs for front end errors (RHF) and server errors (builder context) */
              {...answerInput}
              aria-invalid={!!hasErrors}
              aria-label={`option ${optionNumber}`}
              className={classNames('form-control', { 'is-invalid': hasErrors })}
              disabled={isBoolean}
              name={answerName}
              style={isEditing ? null : { display: 'none' }}
              onChange={handleTextChange}
              onClick={handleClick}
            />
            <div className="invalid-feedback">
              {hasErrors && "Answer can't be blank"}
            </div>
          </div>
        </div>
      </div>
      { isScreenerForm && (
      <div className="SurveyBuilderRow__controls">
        <QualifyLogicSelect
          className="SurveyBuilderQuestion__type answer"
          isPickOne={isPickOne}
          value={answer.qualifyLogic}
          onAnswerChange={onChange}
        />
      </div>
      )}

      {!isBoolean && (
        <div className="SurveyBuilderRow__actions">
          <IconButton
            action="ADD"
            ariaLabel={`add an option after option ${optionNumber}`}
            onClick={handleAdd}
          />
          <IconButton
            action="DELETE"
            ariaLabel={`remove option ${optionNumber}`}
            disabled={!isRemovableAnswer}
            onClick={handleRemove}
          />
        </div>
      )}
    </div>
    /* eslint-enable */
  );
}

Editing.propTypes = {
  answer: propTypes.shape({
    errors: propTypes.shape({
      text: propTypes.string,
    }).isRequired,
    qualifyLogic: propTypes.string.isRequired,
    text: propTypes.string,
    uuid: propTypes.string.isRequired,
  }).isRequired,
  index: propTypes.number.isRequired,
  isEditing: propTypes.bool.isRequired,
  isRemovableAnswer: propTypes.bool.isRequired,
  questionId: propTypes.string.isRequired,
  questionType: propTypes.string.isRequired,
  onAdd: propTypes.func.isRequired,
  onChange: propTypes.func.isRequired,
  onRemove: propTypes.func.isRequired,
};
