import React, { useCallback } from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import { useController, useFormContext } from 'react-hook-form';

import './text.scss';

export function Text({
  isEditing,
  isMandatory,
  serverErrors,
  text,
  onChange,
  uuid,
}) {
  const { formState: { errors } } = useFormContext();
  const questionName = `question.${uuid}`;
  const { field: questionInput } = useController({
    defaultValue: text,
    name: questionName,
    rules: { required: true },
  });

  const hasErrors = !!errors.question?.[uuid] || serverErrors;
  const error = errors.question?.[uuid]
  const errorMessage = error?.type === 'required' ? "Question can't be blank" : error?.message;
  // Prevent a click from triggering parent event
  const handleClick = event => event.stopPropagation();

  const handleChange = useCallback(({ target: { value } }) => {
    questionInput.onChange(value);
    onChange({ text: value });
  }, [onChange, questionInput]);

  return (
    <div className="SurveyBuilderRow__text__form">
      <textarea
        {...questionInput}
        aria-invalid={!!errors}
        aria-label="question text"
        className={classNames('form-control question', { 'is-invalid': hasErrors })}
        name={`question.${uuid}`}
        style={isEditing ? null : { display: 'none' }}
        onChange={handleChange}
        onClick={handleClick}
      />
      <div className="invalid-feedback" style={isEditing ? null : { display: 'none' }}>
        {serverErrors || errorMessage}
      </div>
      <span
        className={classNames({ 'required-field': isMandatory })}
        style={!isEditing ? { whiteSpace: 'pre-wrap' } : { display: 'none' }}
      >
        {text}
      </span>
    </div>
  );
}

Text.propTypes = {
  isEditing: propTypes.bool.isRequired,
  isMandatory: propTypes.bool.isRequired,
  serverErrors: propTypes.string,
  text: propTypes.string,
  uuid: propTypes.string.isRequired,
  onChange: propTypes.func.isRequired,
};

Text.defaultProps = {
  serverErrors: undefined,
  text: '',
};
