import * as propTypes from 'lib/prop_types';

export const UUID = propTypes.string;

export const workingHoursPropType = propTypes.arrayOf(
  propTypes.shape({
    daysOfWeek: propTypes.arrayOf(propTypes.number).isRequired,
    display: propTypes.string.isRequired,
    endTime: propTypes.string.isRequired,
    startTime: propTypes.string.isRequired,
  }),
);

export const eventPropType = propTypes.shape({
  calendarEventId: propTypes.string,
  end: propTypes.object.isRequired,
  numScheduled: propTypes.number.isRequired,
  numSlots: propTypes.number.isRequired,
  start: propTypes.object.isRequired,
  uuid: UUID.isRequired,
});

export const savedCalendarStatePropType = propTypes.shape({
  defaultDate: propTypes.object,
});
