/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext } from 'react';
import propTypes from 'prop-types';
import { Button } from '@user-interviews/ui-design-system';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from 'lib/font_awesome/regular';

import SurveyContext from '../../../context';

export function Actions({
  isReadonly,
  skipLogicCondition,
  skipLogicId,
}) {
  if (isReadonly) return null;

  const { skipLogicFunctions } = useContext(SurveyContext);
  const handleAdd = () =>
    skipLogicFunctions.addCondition(skipLogicId, skipLogicCondition.uuid);
  const handleRemove = () =>
    skipLogicFunctions.removeCondition(skipLogicId, skipLogicCondition.uuid);

  return (
    <>
      <Button
        className="btn btn-remove"
        key="remove"
        variant="default"
        onClick={handleRemove}
      >
        <FontAwesomeIcon icon={faMinus} />
      </Button>
      <Button
        className="btn btn-add"
        key="add"
        variant="default"
        onClick={handleAdd}
      >
        <FontAwesomeIcon icon={faPlus} />
      </Button>
    </>
  );
}

Actions.propTypes = {
  isReadonly: propTypes.bool.isRequired,
  skipLogicCondition: propTypes.object.isRequired,
  skipLogicId: propTypes.string.isRequired,
};
