import React, { Component } from 'react';
import { Prompt } from 'react-router-dom';

import { cleanup, listen } from 'lib/events/navigate_away_warning';
import * as propTypes from 'lib/prop_types';

class NavigationPrompt extends Component {
  static propTypes = {
    message: propTypes.oneOfType([propTypes.string, propTypes.func]).isRequired,
    when: propTypes.func.isRequired,
  };

  componentDidMount() {
    this.navigateWarning = listen({ message: this.props.message, showWarning: this.props.when });
  }

  componentWillUnmount() {
    cleanup(this.navigateWarning);
  }

  render() {
    return (
      <Prompt message={this.props.message} when={this.props.when()} />
    );
  }
}

export default NavigationPrompt;
