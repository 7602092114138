import React, { useCallback } from 'react';
import { useTracking } from 'react-tracking';
import { Mention, MentionsInput } from 'react-mentions';
import { Button, ProfileCell } from '@user-interviews/ui-design-system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { trackingEvents, trackingPropsShape } from 'lib/analytics';
import * as propTypes from 'lib/prop_types';
import { faPlusCircle } from 'lib/font_awesome/solid';

import './edit_control.scss';

// 1. '@' character
// 2. Must be at start of string or preceded by a space
// 3. Can contain any number of non whitespace characters
// 4. Pattern stops matching on a whitespace character at end
const COMMENTER_MENTION_TRIGGER = /(?:^|\s)(@([\S]*)$)/;

export function EditControl({
  commenters = {},
  inviteDomain = '',
  value = '',
  onChange,
  onInvite,
  onKeyDown,
}) {
  const { trackEvent } = useTracking();

  const buildMentionData = useCallback((query) => {
    const inviteSuggestion =
      query.search(/@/) < 0 ?
        `${query}@${inviteDomain}` :
        query

    const mentionData =
      onInvite ?
        [{ display: query, id: inviteSuggestion }] :
        [];

    return (
      Object
        .entries(commenters)
        .reduce((acc, [id, commenter]) => {
          if (commenter.fullName.search(new RegExp(query, 'i')) < 0) {
            return acc;
          }

          acc.unshift({
            display: commenter.fullName,
            id,
          });
          return acc;
        }, mentionData)
    )
  }, [commenters, inviteDomain, onInvite]);

  const handleAdd = useCallback((id) => {
    if (!onInvite || commenters[id]) return;

    trackEvent({ event: trackingEvents.COMMENTS_INVITE_FROM_MENTION });
    onInvite([id]);
  }, [onInvite, commenters, trackEvent]);

  const renderMention = useCallback((id) => (commenters[id] ?
    `@${commenters[id].fullName}` :
    `@${id}`), [commenters]);

  const renderSuggestion = useCallback(({ id }) => {
    if (commenters[id]) {
      return (
        <ProfileCell
          colorId={commenters[id].id}
          subtitle={commenters[id].email}
          user={commenters[id]}
        />
      );
    }

    return (
      <Button variant="link">
        <FontAwesomeIcon
          className="icon-left"
          fixedWidth
          icon={faPlusCircle}
        />
        {`Invite ${id}`}
      </Button>
    );
  }, [commenters]);

  return (
    <MentionsInput
      allowSpaceInQuery
      allowSuggestionsAboveCursor
      className="EditControl__input"
      placeholder="Add a project note"
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}
    >
      <Mention
        appendSpaceOnAdd
        className="EditControl__mention"
        data={buildMentionData}
        displayTransform={renderMention}
        markup="@(__id__)"
        renderSuggestion={renderSuggestion}
        trigger={COMMENTER_MENTION_TRIGGER}
        onAdd={handleAdd}
      />
    </MentionsInput>
  );
}

EditControl.propTypes = {
  commenters: propTypes.objectOf(
    propTypes.shape({
      email: propTypes.string,
      fullName: propTypes.string,
      id: propTypes.oneOfType([propTypes.string, propTypes.number]),
    }),
  ).isRequired,
  // eslint-disable-next-line react/require-default-props
  inviteDomain: propTypes.string,
  value: propTypes.string.isRequired,
  onChange: propTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  onInvite: propTypes.func,
  onKeyDown: propTypes.func.isRequired,
  ...trackingPropsShape,
};
