import React, { useContext } from 'react';
import propTypes from 'prop-types';
import { Alert, MessageTypes } from '@user-interviews/ui-design-system';

import { AuthUserContext } from '../authorization';

function MultipleTeamsDisclaimer({ message, title, warn }) {
  const { organization } = useContext(AuthUserContext);

  if (!organization.multipleTeams) {
    return null;
  }

  const defaultAlertMessage =
    `${warn ? '⚠️' : ''} Any change on this page will affect all teams within your organization.`;

  return (
    <Alert
      message={message || defaultAlertMessage}
      removeBorderLeft
      title={title || 'Your organization has multiple teams'}
      type={warn ? MessageTypes.WARNING : MessageTypes.INFO}
    />
  );
}

MultipleTeamsDisclaimer.propTypes = {
  message: propTypes.oneOfType([propTypes.string, propTypes.element]),
  title: propTypes.string,
  warn: propTypes.bool,
};

MultipleTeamsDisclaimer.defaultProps = {
  message: undefined,
  title: undefined,
  warn: false,
};

export default MultipleTeamsDisclaimer;
