import React, { useCallback, useMemo } from 'react';
import propTypes from 'prop-types';
import { SingleSelect } from '@user-interviews/ui-design-system';

import { sortByStringKey } from 'lib/collections';

function TeamSelect({ selectedTeamId, teams, onChange }) {
  const teamOptions = useMemo(() => (
    [
      { label: 'All Teams', value: null },
      ...sortByStringKey(teams, 'name').map(team => (
        { label: team.name, value: team.id }
      )),
    ]
  ), [teams]);

  const selectedOption = useMemo(() => (
    teamOptions.find(option => option.value === selectedTeamId)
  ), [selectedTeamId, teamOptions]);

  const onChangeTeam = useCallback((selected) => {
    onChange(teams.find(t => t.id === selected.value));
  }, [onChange, teams]);

  return (
    <SingleSelect
      aria-label="select a team"
      className="TeamSelect"
      inputId="team-select"
      options={teamOptions}
      value={selectedOption}
      onChange={onChangeTeam}
    />
  );
}

TeamSelect.propTypes = {
  selectedTeamId: propTypes.string,
  teams: propTypes.arrayOf(propTypes.object).isRequired,
  onChange: propTypes.func.isRequired,
};

TeamSelect.defaultProps = {
  selectedTeamId: null,
};

export default TeamSelect;
