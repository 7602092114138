import React, { useCallback } from 'react';
import propTypes from 'prop-types';

import { SingleSelect } from '@user-interviews/ui-design-system';

import * as Constants from '../../constants';

export function QualifyLogicSelect({
  className,
  isPickOne,
  value,
  onAnswerChange,
}) {
  const handleClick = event => event.stopPropagation();

  const handleQualifyLogicChange = useCallback(
    selected => onAnswerChange({ qualifyLogic: selected.value }),
    [onAnswerChange],
  );

  const options = isPickOne ? Constants.PICK_ONE_QUALIFY_LOGIC_OPTIONS : Constants.QUALIFY_LOGIC_OPTIONS;

  const { label } = options.find(option => option.value === value);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className={className}
      onClick={handleClick}
    >
      <SingleSelect
        aria-label="qualify logic select"
        inputKey="answer-select"
        options={options}
        value={{ label, value }}
        onChange={handleQualifyLogicChange}
      />
    </div>
  );
}

QualifyLogicSelect.propTypes = {
  className: propTypes.string,
  isPickOne: propTypes.bool.isRequired,
  value: propTypes.string.isRequired,
  onAnswerChange: propTypes.func.isRequired,
};

QualifyLogicSelect.defaultProps = {
  className: '',
};
