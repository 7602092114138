import React, { useCallback, useContext, useMemo } from 'react';
import propTypes from 'prop-types';
import { SingleSelect } from '@user-interviews/ui-design-system';

import SurveyContext from 'common/components/survey_builder/context';
import * as Constants from '../constants';

const questionTypes = Constants.QUESTION_TYPES.map(questionType => (
  { label: questionType, value: questionType }
));

export function Type({
  isVisible,
  type,
  onChange,
}) {
  const { formType } = useContext(SurveyContext);

  const filteredQuestionTypes = useMemo(() => {
    if (formType === Constants.SCREENER_FORM_TYPE) {
      return questionTypes.filter(
        questionType =>
          questionType.label !== Constants.QUESTION_TYPE_BOOLEAN &&
          questionType.label !== Constants.QUESTION_TYPE_DATE &&
          questionType.label !== Constants.QUESTION_TYPE_FLOAT,
      );
    }
    if (formType === Constants.OPT_IN_FORM_TYPE) {
      return questionTypes.filter(
        questionType =>
          questionType.label !== Constants.QUESTION_TYPE_PICK_ANY_GRID &&
          questionType.label !== Constants.QUESTION_TYPE_PICK_ONE_GRID,
      );
    }
    return questionTypes;
  }, [formType]);

  // Prevent a click from triggering parent event
  const handleClick = event => event.stopPropagation();

  const handleChange = useCallback((selected) => {
    onChange({ questionType: selected.value });
  }, [onChange]);

  if (!isVisible) return null;

  return (
    /* eslint-disable jsx-a11y/no-static-element-interactions */
    /* eslint-disable jsx-a11y/click-events-have-key-events */
    <div className="SurveyBuilderQuestion__type" onClick={handleClick}>
      <SingleSelect
        aria-label="question type select"
        inputId="question-type-select"
        options={filteredQuestionTypes}
        value={{ label: type, value: type }}
        onChange={handleChange}
      />
    </div>
    /* eslint-enable */
  );
}

Type.propTypes = {
  isVisible: propTypes.bool.isRequired,
  type: propTypes.string.isRequired,
  onChange: propTypes.func.isRequired,
};
