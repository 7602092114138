import React from 'react';
import propTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from '@user-interviews/ui-design-system';

import { TrackedDropdownItem } from 'common/analytics';

import { trackingEvents } from 'lib/analytics';
import { faEllipsisV } from 'lib/font_awesome/regular';

export function SectionDropdown({
  className,
  removeable,
  onDuplicate,
  onRemove,
}) {
  return (
    <Dropdown className={className}>
      <DropdownToggle
        aria-label="dropdown toggle screener page options"
        removeCaret
        variant="transparent"
      >
        <FontAwesomeIcon icon={faEllipsisV} size="lg" />
      </DropdownToggle>
      <DropdownMenu>
        <TrackedDropdownItem
          aria-label="Duplicate screener page"
          event={trackingEvents.SURVEY_DUPLICATE_PAGE_CLICKED}
          onClick={onDuplicate}
        >
          Duplicate
        </TrackedDropdownItem>

        {removeable && (
        <DropdownItem
          aria-label="Remove Screener Page"
          onClick={onRemove}
        >
          Delete
        </DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  )
}

SectionDropdown.propTypes = {
  className: propTypes.string,
  removeable: propTypes.bool.isRequired,
  onDuplicate: propTypes.func.isRequired,
  onRemove: propTypes.func.isRequired,
};

SectionDropdown.defaultProps = {
  className: undefined,
};
