import React from 'react';
import * as propTypes from 'lib/prop_types';

import styles from './labeled_divider.module.scss';

function LabeledDivider({ label }) {
  return (
    <div className={styles.LabeledDivider}>
      <hr />
      <span className={styles.LabeledDivider__label}>{label}</span>
      <hr />
    </div>
  )
}

LabeledDivider.propTypes = {
  label: propTypes.string.isRequired,
};

export default LabeledDivider;
