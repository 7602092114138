import React, { useContext } from 'react';
import propTypes from 'prop-types';
import { FormGroup, Input } from '@user-interviews/ui-design-system';
import SurveyContext from '../context';

export function Hint({ question }) {
  const { questionFunctions: { update: updateQuestion } } = useContext(SurveyContext);

  const handleHintChange = ({ target: { value } }) => {
    updateQuestion(
      question.uuid,
      { helpText: value },
    );
  };

  return (
    <FormGroup
      inputKey={`question-${question.uuid}-hint`}
      label="Hint text"
      labelHelperText="Optional"
      labelHtmlFor={`hint-text-${question.uuid}`}
    >
      <Input
        id={`hint-text-${question.uuid}`}
        name={`hint-text-${question.uuid}`}
        placeholder="Give context on this question to participants"
        value={question.helpText || ''}
        onChange={handleHintChange}
      />
    </FormGroup>
  );
}

Hint.propTypes = {
  question: propTypes.shape({
    helpText: propTypes.string,
    uuid: propTypes.string.isRequired,
  }).isRequired,
};
