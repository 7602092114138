import { Component } from 'react';
import { Button, ButtonVariants, LoadingOverlay } from '@user-interviews/ui-design-system';
import classNames from 'classnames';
import * as propTypes from 'lib/prop_types';

import {
  track,
  trackingEvents,
  trackingPropsShape,
} from 'lib/analytics';

import ConnectCalendarModal from './connect_calendar_modal';
import ConnectedCalendarMessage from './connected_calendar_message';
import { connectedCalendarDetailsPropType } from './prop_types';

import styles from './connect_calendar.module.scss';

class ConnectCalendar extends Component {
  static propTypes = {
    connectedCalendarDetails: connectedCalendarDetailsPropType,
    isDisabled: propTypes.bool,
    isLink: propTypes.bool,
    isReadonly: propTypes.bool,
    isSyncingCalendar: propTypes.bool,
    showAccountAvailabilityLink: propTypes.bool,
    onUpdateParams: propTypes.func.isRequired,
    ...trackingPropsShape,
  };

  static defaultProps = {
    isLink: false,
    connectedCalendarDetails: undefined,
    isSyncingCalendar: false,
    isReadonly: false,
    isDisabled: false,
    showAccountAvailabilityLink: false,
  };

  state = {
    modalIsOpen: false,
  };

  handleConnect = (provider) => {
    // This is linked to Scheduling::ConnectCalendarConcern::PARAM_CALENDAR_PROVIDER
    this.props.onUpdateParams({ selectedCalendarProvider: provider });
    this.props.tracking.trackEvent({
      event: trackingEvents.CONNECT_CALENDAR_SYNCED,
      provider,
    });
    this.handleModalRequestClose();
  };

  handleConnectClicked = () => {
    this.setState({ modalIsOpen: true });
  };

  handleModalRequestClose = () => {
    this.setState({ modalIsOpen: false });
  };

  get isConnected() {
    return !!this.props.connectedCalendarDetails;
  }

  renderConnect() {
    if (this.props.isReadonly) return null;

    return (
      <Button
        className={styles.ConnectButton}
        disabled={this.props.isDisabled}
        onClick={this.handleConnectClicked}
        {...(this.props.isLink ? { variant: ButtonVariants.LINK } : {})}
      >
        Connect your calendar
      </Button>
    );
  }

  renderConnected() {
    return (
      <ConnectedCalendarMessage
        connectedCalendarDetails={this.props.connectedCalendarDetails}
        isReadonly={this.props.isReadonly}
        showAccountAvailabilityLink={this.props.showAccountAvailabilityLink}
      />
    );
  }

  render() {
    return (
      <div className={classNames('connect-calendar', styles.ConnectCalendar)}>
        <ConnectCalendarModal
          isOpen={this.state.modalIsOpen}
          onConnect={this.handleConnect}
          onRequestClose={this.handleModalRequestClose}
        />
        <LoadingOverlay visible={this.props.isSyncingCalendar} />
        {
          this.isConnected ?
            this.renderConnected() :
            this.renderConnect()
        }
      </div>
    );
  }
}

export default track()(ConnectCalendar);
