import React, { useMemo } from 'react';
import propTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as constants from '../../../lib/constants/sort_data_actions_constants';

export function SortIndicator({
  sortableType,
  sortParamKey,
  sortParamString,
}) {
  const currentSortDirection = useMemo(() => {
    if (!sortParamString || !sortParamString?.includes(sortParamKey)) {
      return undefined;
    }
    return sortParamString?.[0] === '-' ?
      constants.SORT_DIRECTION_DESCENDING :
      constants.SORT_DIRECTION_ASCENDING;
  }, [sortParamString, sortParamKey]);

  const sortIcon = sortableType && constants.SORT_CONTENT_MAP[sortableType][currentSortDirection];

  if (!sortIcon) return null;

  return (
    <FontAwesomeIcon
      className="SortDataActions__sort-indicator-icon"
      icon={sortIcon}
    />
  );
}

SortIndicator.propTypes = {
  sortableType: propTypes.oneOf([
    constants.SORT_TYPE_DATE,
    constants.SORT_TYPE_NUMERIC,
    constants.SORT_TYPE_STRING,
    '',
  ]),
  sortParamKey: propTypes.string,
  sortParamString: propTypes.string,
};

SortIndicator.defaultProps = {
  sortParamKey: '',
  sortParamString: '',
  sortableType: '',
};
