import React, { useCallback, useContext } from 'react';
import propTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Flex, MessageTypes } from '@user-interviews/ui-design-system';

import { TrackedButton } from 'common/analytics';
import { useSidebarMobileBreakpoint } from 'hooks/use_sidebar_mobile_breakpoint';

import { trackingEvents } from 'lib/analytics';
import { faBullseyePointer, faPlus } from 'lib/font_awesome/regular';

import {
  MAX_COLUMN_COUNT,
  MAX_ROW_COUNT,
  MIN_COLUMN_COUNT,
  MIN_ROW_COUNT,
} from 'lib/surveys/builder/question_group_utilities';

import SurveyContext from '../../context';

import { Accept } from './accept';
import { GridAnswer } from './grid_answer';
import { GridQuestion } from './grid_question';

import styles from './grid.module.scss';

export const QUAL_LOGIC_INSTRUCTIONS = 'Edit qualification logic by clicking on the cells within the grid';
export function Grid({
  isEditing,
  questionGroupId,
}) {
  const isMobile = useSidebarMobileBreakpoint();

  const {
    answerFunctions: {
      update: updateAnswer,
    },
    questionFunctions: {
      update: updateQuestion,
    },
    questionGroupFunctions: {
      addColumn,
      addRow,
      removeColumn,
      removeRow,
      updateColumn,
    },
    survey,
  } = useContext(SurveyContext);

  const questionGroup = survey.questions[questionGroupId];
  const { questionIds } = questionGroup;

  const firstQuestion = survey.questions[questionIds[0]];
  const firstAnswers = firstQuestion.answerIds;

  const answerCount = firstQuestion.answerIds.length;
  const questionCount = questionGroup.questionIds.length;

  const handleAnswerChange = useCallback((answerUUID, changes) => {
    updateAnswer(answerUUID, changes);
  }, [updateAnswer]);

  const handleQuestionChange = useCallback((questionUUID, changes) => {
    updateQuestion(questionUUID, changes);
  }, [updateQuestion]);

  const handleAddColumn = useCallback((event) => {
    event.stopPropagation();

    addColumn(questionGroup.uuid);
  }, [addColumn, questionGroup.uuid]);

  const handleAddRow = useCallback((event) => {
    event.stopPropagation();

    addRow(questionGroup.uuid, answerCount);
  }, [addRow, answerCount, questionGroup.uuid]);

  const handleRemoveColumn = useCallback((answerIndex) => {
    removeColumn(questionGroup.uuid, answerIndex);
  }, [questionGroup.uuid, removeColumn]);

  const handleRemoveRow = useCallback((questionUUID) => {
    removeRow(questionGroup.uuid, questionUUID);
  }, [questionGroup.uuid, removeRow]);

  const handleUpdateColumn = useCallback((answerIndex, changes) => {
    updateColumn(questionGroup.uuid, answerIndex, changes);
  }, [questionGroup.uuid, updateColumn]);

  if (!questionGroup) return null;

  if (isMobile) {
    return (
      <Alert
        id={`question-type-unavailable-${questionGroup.uuid}`}
        message="Please switch to desktop view to build this question type"
        removeBorderLeft
        title="Question type not available"
        type={MessageTypes.INFO}
      />
    );
  }

  const boxStyle = styles[`box${answerCount + 1}`];

  return (
    <>
      <div className={styles.gridRow}>
        <div className={styles.answerRow}>
          <div className={boxStyle} />
          {firstAnswers && firstAnswers.map((answerUUID, index) => (
            <div className={boxStyle} key={answerUUID}>
              <GridAnswer
                answer={survey.answers[answerUUID]}
                index={index}
                isEditing={isEditing}
                isRemovable={firstAnswers.length > MIN_COLUMN_COUNT}
                questionGroupId={questionGroup.uuid}
                onChange={handleAnswerChange}
                onRemove={handleRemoveColumn}
                onUpdateColumn={handleUpdateColumn}
              />
            </div>
          ))}
        </div>
      </div>

      {questionGroup.questionIds.map(questionUUID => (
        <div className={styles.gridRow} key={questionUUID}>
          <div className={styles.boxRow}>
            <div className={boxStyle}>
              <GridQuestion
                isEditing={isEditing}
                isRemovable={questionGroup.questionIds.length > MIN_ROW_COUNT}
                question={survey.questions[questionUUID]}
                questionGroupId={questionGroup.uuid}
                onChange={handleQuestionChange}
                onRemove={handleRemoveRow}
              />
            </div>
            {survey.questions[questionUUID].answerIds.filter(answerUUID => !!survey.answers[answerUUID]).map(answerUUID => (
              <div className={boxStyle} key={`accept-${answerUUID}`}>
                <Accept
                  answer={survey.answers[answerUUID]}
                  isEditing={isEditing}
                  questionGroupPick={questionGroup.pick}
                  onChange={handleAnswerChange}
                />
              </div>
            ))}
          </div>
        </div>
      ))}

      {isEditing && (
        <>
          <Flex className={styles.addButtons}>
            <TrackedButton
              className={styles.addButton}
              disabled={questionCount >= MAX_ROW_COUNT}
              event={trackingEvents.SURVEY_ADD_GRID_ROW}
              leadingIcon={faPlus}
              type="button"
              variant="outline-transparent"
              onClick={handleAddRow}
            >
              Add row
            </TrackedButton>

            <TrackedButton
              className={styles.addButton}
              disabled={answerCount >= MAX_COLUMN_COUNT}
              event={trackingEvents.SURVEY_ADD_GRID_COLUMN}
              leadingIcon={faPlus}
              type="button"
              variant="outline-transparent"
              onClick={handleAddColumn}
            >
              Add column
            </TrackedButton>
          </Flex>
          <Flex className={styles.qualificationInstructions}>
            <FontAwesomeIcon
              className="icon-left"
              fixedWidth
              icon={faBullseyePointer}
            />
            {QUAL_LOGIC_INSTRUCTIONS}
          </Flex>
        </>
      )}
    </>
  );
}

Grid.propTypes = {
  isEditing: propTypes.bool.isRequired,
  questionGroupId: propTypes.string.isRequired,
};
