/* global StripeCheckout */

import React, { Component } from 'react';
import * as propTypes from 'lib/prop_types';

import { ErrorRenderer } from 'lib/errors';
import Http, { FormatResponse } from 'lib/http';
import {
  stripeCardIdPropType,
  stripeCardPropType,
} from 'lib/prop_types/billing';
import * as routes from 'lib/routes';
import { SingleSelect } from '@user-interviews/ui-design-system';
import UserInterviewsLogo from 'images/ui-logo-icon-navy-bg.png';
import { ENV } from 'lib/react_on_rails/env';

const ADD_CARD = 'add';

export default class StripeCardSelector extends Component {
  static propTypes = {
    accountEmail: propTypes.string.isRequired,
    accountId: propTypes.oneOfType([propTypes.number, propTypes.string]).isRequired,
    className: propTypes.string,
    disabled: propTypes.bool,
    id: propTypes.string,
    inputId: propTypes.string.isRequired,
    modal: propTypes.bool,
    name: propTypes.string,
    stripeCardId: stripeCardIdPropType,
    stripeCards: propTypes.arrayOf(stripeCardPropType).isRequired,
    onStripeCardChanged: propTypes.func.isRequired,
    onStripeCardsChanged: propTypes.func.isRequired,
    onToggleLoading: propTypes.func,
  };

  static defaultProps = {
    className: undefined,
    disabled: false,
    id: undefined,
    modal: undefined,
    name: undefined,
    stripeCardId: '',
    onToggleLoading: () => { },
  };

  handleStripeCardChanged = (option) => {
    if (option.value === ADD_CARD) {
      this.stripeHandler.open({
        allowRememberMe: false,
        email: this.props.accountEmail,
      });
      return;
    }

    this.props.onStripeCardChanged(option.value);
  };

  handleToken = async (token) => {
    this.props.onToggleLoading(true);

    try {
      const response = await Http.post(
        routes.api_account_stripe_cards_path(this.props.accountId),
        { data: { attributes: { token: token.id } } },
      );

      const newCard = FormatResponse.jsonApi({})(response);

      const updatedCards = [...this.props.stripeCards, newCard];
      this.props.onStripeCardsChanged(updatedCards);
      this.props.onStripeCardChanged(newCard.id);
    } catch (err) {
      ErrorRenderer.alert(err, 'fetching stripe cards');
    } finally {
      this.props.onToggleLoading(false);
    }
  };

  componentDidMount() {
    this.stripeHandler = StripeCheckout.configure({
      billingAddress: true,
      image: UserInterviewsLogo,
      key: ENV?.STRIPE_PUBLIC_KEY,
      panelLabel: 'Save Card',
      token: this.handleToken,
    });
  }

  componentWillUnmount() {
    this.stripeHandler.close();
  }

  render() {
    const cardOptions = this.props.stripeCards.map(card => ({
      // TODO 18338: Cut over to completely using displayName vs. formatting here
      label: card.displayName || `**** **** **** ${card.lastFour} [${card.brand}]`,
      value: card.id,
    }));

    const options = [
      ...cardOptions,
      {
        label: '+ Add Card',
        value: ADD_CARD,
      },
    ];

    const selectedOption = options.find(option => option.value === this.props.stripeCardId);

    return (
      <SingleSelect
        aria-label="Select a card"
        className={this.props.className}
        disabled={this.props.disabled}
        getOptionLabel={option => option.label}
        getOptionValue={option => option.value}
        id={this.props.id}
        inputId={this.props.inputId}
        modal={this.props.modal}
        name={this.props.name}
        options={options}
        placeholder="Select a card"
        value={selectedOption}
        onChange={this.handleStripeCardChanged}
      />
    );
  }
}
